//Module Imports
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

var interact = require('interact.js');

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Segmented Control : Segmented Control must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "initialActive",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["number"],
        errorMessage            :   ["GDK Segmented Control : initialActive must be set to a number"]
    },
    {
        setting                 :   "segmentedControlChange",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK Segmented Control : segmentedControlChange must be a defined and set function"]
    }
];

class SegmentedControl {
    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string, Object} content
     * A reference to the segmented control component node
     *
     * @param {number} [initialActive=1]
     * The number of the item to set as the active selection on initialization. Must be greater than 0 and equal to or less than the number of segment options
     *
     * @param {function} [segmentedControlChange]
     * A callback function that is triggered when the Segmented Control selection is changed
     */
    constructor(options) {

        this._internalVars = {};

        //options with defaults set
        this._defaults = {
            initialActive: 1
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            init.call(this);
            setEvents.call(this);

            Array.prototype.forEach.call(this._internalVars.dropZoneObject, (element)=>{
                element.setAttribute('role', 'switch');
                if(element.classList.contains('active')){
                    element.setAttribute('aria-checked', 'true');
                }else{
                    element.setAttribute('aria-checked', 'false');
                }
            });
        }
    }

    //Public Methods

    /**
     * setInitialActiveOpt(index)
     * @param {number} index
     * sets the initial active segment
     */

    setInitialActiveOpt(index) {
        setInitialActive.call(this, index);
    }

    /**
     * activeSegment()
     * returns the current active segment anchor element
     * @returns {Element}
     */
    activeSegment() {
        return this._internalVars.selectedSegment;
    }

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.segmentedControlObject = this._internalVars.node;

    this._internalVars.draggableClass = '.sc-draggable';
    this._internalVars.dropZoneClass = '.dropzone';
    this._internalVars.animateClass = 'animate-drop';
    this._internalVars.dropActiveClass = 'drop-active';
    this._internalVars.dropTargetClass = 'drop-target';
    this._internalVars.activeClass = 'active';
    this._internalVars.activeDragClass = 'active-drag';

    this._internalVars.dropZoneObject = this._internalVars.segmentedControlObject.querySelectorAll(this._internalVars.dropZoneClass);
    this._internalVars.draggableObject = this._internalVars.segmentedControlObject.querySelector(this._internalVars.draggableClass);

    this._internalVars.selectedSegment = null;
}

function init() {
    let index = this._options.initialActive;

    setInitialActive.call(this, index);

    interact(this._internalVars.draggableObject)
        .draggable({
            inertia: true,
            restrict: {
                restriction: 'parent',
                endOnly: false,
                elementRect: { top: 0, left: 0, bottom: 0, right: 1 }
            },
            autoScroll: false,
            snap: {
                targets : [
                    {y: 0, range: Infinity}
                ]

            },

            onmove: scDragMoveListener.bind(this)
        });

    function scDragMoveListener (event) {
        let target = event.target,
            x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
            y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

        target.style.left = x + "px";

        target.setAttribute('data-x', x);
        target.setAttribute('data-y', 0);

        target.innerText = '';
        target.style.zIndex = 1;
        target.classList.add(this._internalVars.activeDragClass);
    }

    Array.prototype.forEach.call(this._internalVars.dropZoneObject, (element) =>{
        interact(element)
            .dropzone({
                accept: this._internalVars.draggableClass,

                overlap: 0.4,

                ondropactivate: dropActivate.bind(this),

                ondragenter: dragEnter.bind(this),

                ondragleave: dragLeave.bind(this),

                ondrop: onDrop.bind(this),

                ondropdeactivate: dropDeactivate.bind(this)
            });
    });

    function dropActivate(event) {
        event.target.classList.add(this._internalVars.dropActiveClass);
    }

    function dragEnter(event) {
        let dropzoneElement = event.target;

        dropzoneElement.classList.add(this._internalVars.dropTargetClass);
    }

    function dragLeave(event) {
        event.target.classList.remove(this._internalVars.dropTargetClass);
    }

    function onDrop(event) {
        let xcord = event.target.offsetLeft,
            delay = setTimeout(setNewSelection.bind(this), 350),
            current = event.target.offsetParent.querySelector('.' + this._internalVars.activeClass);

        current.classList.remove(this._internalVars.activeClass);
        current.setAttribute('aria-checked', 'false');
        event.target.classList.add(this._internalVars.activeClass);
        event.target.setAttribute('aria-checked', 'true');
        event.relatedTarget.classList.add(this._internalVars.animateClass);
        event.relatedTarget.setAttribute('data-x', xcord);
        event.relatedTarget.style.left =  xcord + 'px';

        function setNewSelection() {
            event.relatedTarget.textContent = event.target.textContent;
            event.relatedTarget.style.zIndex = 2;
            event.relatedTarget.classList.remove(this._internalVars.animateClass);
            event.relatedTarget.classList.remove(this._internalVars.activeDragClass);
            this._internalVars.segmentedControlObject.querySelector('.' + this._internalVars.activeClass).focus();
        }

        this._internalVars.selectedSegment = event.target;

        if(this._options.segmentedControlChange) {
            this._options.segmentedControlChange(event.target);
        }
    }

    function dropDeactivate(event) {
        event.target.classList.remove(this._internalVars.dropActiveClass);
        event.target.classList.remove(this._internalVars.dropTargetClass);
    }
}

function setEvents() {
    const eventName = UserAgentService._clickEventName();

    Array.prototype.forEach.call(this._internalVars.dropZoneObject, (element)=>{
        element.addEventListener(eventName, setActiveSelection.bind(this));
        element.addEventListener('focus', function(){
            if(element.classList.contains('active')){
                element.parentNode.firstElementChild.style.textDecoration = 'underline';
            }else{
                element.parentNode.firstElementChild.style.textDecoration = 'none';
            }
        });
        element.addEventListener('blur', function(){
            element.parentNode.firstElementChild.style.textDecoration = 'none';
        });
    });

    this._internalVars.draggableObject.addEventListener(eventName, function () {
        this.parentNode.querySelector('.active').focus();
        this.style.textDecoration = 'underline';
    });

    window.addEventListener('resize', resetActiveSelection.bind(this));
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();

    Array.prototype.forEach.call(this._internalVars.dropZoneObject, (element)=>{
        element.removeEventListener(eventName, setActiveSelection.bind(this));
        element.removeEventListener('focus', function(){
            if(element.classList.contains('active')){
                element.parentNode.firstElementChild.style.textDecoration = 'underline';
            }else{
                element.parentNode.firstElementChild.style.textDecoration = 'none';
            }
        });
        element.removeEventListener('blur', function(){
            element.parentNode.firstElementChild.style.textDecoration = 'none';
        });
    });

    this._internalVars.draggableObject.removeEventListener(eventName, function () {
        this.parentNode.querySelector('.active').focus();
        this.style.textDecoration = 'underline';
    });

    window.removeEventListener('resize', resetActiveSelection.bind(this));
}


function setInitialActive(activeIndex) {

    let activeElement = this._internalVars.segmentedControlObject.querySelector('.' + this._internalVars.activeClass);

    if(activeElement) {
        activeElement.classList.remove(this._internalVars.activeClass);
        activeElement.setAttribute('aria-checked', 'false');
    }

    if(activeIndex <= this._internalVars.dropZoneObject.length && activeIndex > 0) {
        this._internalVars.dropZoneObject[activeIndex -1].classList.add(this._internalVars.activeClass);
    } else {
        this._internalVars.dropZoneObject[0].classList.add(this._internalVars.activeClass);
    }

    activeElement = this._internalVars.segmentedControlObject.querySelector('.' + this._internalVars.activeClass);
    let activeElementLeft = activeElement.offsetLeft,
        activeElementContent = activeElement.innerHTML;

    activeElement.setAttribute('aria-checked', 'true');
    this._internalVars.draggableObject.style.left = activeElementLeft + 'px';
    this._internalVars.draggableObject.innerHTML = activeElementContent;
    this._internalVars.draggableObject.setAttribute('data-x', activeElementLeft);

    this._internalVars.selectedSegment = activeElement;
}

function setActiveSelection(event) {
    event.preventDefault();
    let left = event.target.offsetLeft,
        delay = setTimeout(setNewSelection.bind(this), 350),
        current = event.target.offsetParent.querySelector('.' + this._internalVars.activeClass);

    current.classList.remove(this._internalVars.activeClass);
    current.setAttribute('aria-checked', 'false');
    event.target.classList.add(this._internalVars.activeClass);
    event.target.setAttribute('aria-checked', 'true');
    this._internalVars.draggableObject.classList.add(this._internalVars.animateClass);
    this._internalVars.draggableObject.classList.add(this._internalVars.activeDragClass);
    this._internalVars.draggableObject.innerText = '';
    this._internalVars.draggableObject.style.zIndex = 1;

    this._internalVars.draggableObject.setAttribute('data-x', left);
    this._internalVars.draggableObject.style.left = left + 'px';

    function setNewSelection() {
        this._internalVars.draggableObject.innerHTML = event.target.innerHTML;
        this._internalVars.draggableObject.style.zIndex = 2;
        this._internalVars.draggableObject.classList.remove(this._internalVars.animateClass);
        this._internalVars.draggableObject.classList.remove(this._internalVars.activeDragClass);
        this._internalVars.draggableObject.style.textDecoration = 'underline';
    }

    this._internalVars.selectedSegment = event.target;

    if(this._options.segmentedControlChange) {
        this._options.segmentedControlChange(event.target);
    }
}

function resetActiveSelection() {
    let current = this._internalVars.segmentedControlObject.querySelector('.' + this._internalVars.activeClass),
        left = current.offsetLeft;

    this._internalVars.draggableObject.setAttribute('data-x', left);
    this._internalVars.draggableObject.style.left = left + 'px';
}

export default SegmentedControl;