//Module Imports
import * as utils from '../utils';
import appState from '../appState';

//Global footer vars
let copyRight,
    footerNewYear,
    footerCurrentYear;

function init() {

    console.log('Footer initialized');

    setYear();

}

function setYear() {
    copyRight = document.getElementById('footerDate');
    footerNewYear = new Date();
    footerCurrentYear = footerNewYear.getFullYear();
    copyRight.innerHTML = footerCurrentYear;
}

export {init};
