//Require static assets

//Module Imports
import * as utils from '../utils';
import baseComponent from '../baseComponent';
import appState from '../appState';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",//a type or a value
        possibleValues: ["string", "object"],
        errorMessage: ["GDK CurrencyInput : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting: "numType",
        isRequired: false,
        validate: "value",//a type or a value
        possibleValues: ["whole", "decimal"],
        errorMessage: ["GDK CurrencyInput : numType must be set to whole or decimal"]
    },
    {
        setting: "onCompleteFormat",
        isRequired: false,
        validate: "type",//a type or a value
        possibleValues: ["function"],
        errorMessage: ["GDK CurrencyInput : onCompleteFormat must be a defined and set function"]
    }
];

/**
 * CurrencyInput Class
 */
class CurrencyInput {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the component node of currency input field
     *
     * @param {string} [numType="decimal"]
     *  A string representing the number type of the currency input field
     *
     *  @param {function} [onCompleteFormat]
     *  A callback function fired on blur after formatting is complete in the input field
     */
    constructor(options) {

        console.log('CurrencyInput initialized');

        this._internalVars = {
            node: null //used for current node
        };

        //options with defaults set
        this._defaults = {
                numType: 'decimal'
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);

            if(this._internalVars.node.getAttribute('type') != 'text'){
                this._internalVars.node.setAttribute('type', 'text');
            }

            if(!this._internalVars.node.hasAttribute('placeholder') && this._options.numType == 'decimal'){
                this._internalVars.node.setAttribute('placeholder', '0.00');
            }else if(!this._internalVars.node.hasAttribute('placeholder') && this._options.numType == 'whole'){
                this._internalVars.node.setAttribute('placeholder', '0');
            }

            if(this._internalVars.inputValue!==''){
                this._internalVars.inputValue = parsedValue(this._internalVars.inputValue, this._options.numType);
                this._internalVars.inputValue = insertCommas(this._internalVars.inputValue.toString());
                this._internalVars.node.setAttribute('value', this._internalVars.inputValue);
            }
        }
    }

    //Public Methods

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

    /**
     * getCurrencyInput()
     * return a type float number from currency input field
     * @returns {float}
     */
    getCurrencyInput(){
        return parseFloat(parsedValue(this._internalVars.node.value, this._options.numType));
    }
}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    this._internalVars.node.addEventListener("keydown", this._internalVars.keyDownHandler);
    this._internalVars.node.addEventListener("keyup", this._internalVars.keyUpHandler);
    this._internalVars.node.addEventListener("keypress", this._internalVars.keyPressHandler);
    this._internalVars.node.addEventListener("blur", this._internalVars.blurHandler);
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    this._internalVars.node.removeEventListener("keydown", this._internalVars.keyDownHandler);
    this._internalVars.node.removeEventListener("keyup", this._internalVars.keyUpHandler);
    this._internalVars.node.removeEventListener("keypress", this._internalVars.keyPressHandler);
    this._internalVars.node.removeEventListener("blur", this._internalVars.blurHandler);
}



function decimalExist(value){
    if(value.indexOf('.') != -1){
        return true;
    }else{
        return false;
    }
}

function numOfCharAfterDecimal(value){
    return value.toString().substr(value.toString().indexOf(".") + 1).length;
}

function isCharacterValid(e, type){
        var keyCode = e.which || e.keyCode;
    //numeric								arrow key						backspace and tab					enter
        if((keyCode >= 48 && keyCode <= 57) || (keyCode >= 37 && keyCode <=40) || (keyCode >= 8 && keyCode <= 9) || keyCode == 13 || (type == 'decimal' && keyCode == 190 || keyCode == 46)){
            return true;
        }else{
            return false;
        }
}

function parsedValue(value, type){
    if(type == 'decimal'){
        return parseFloat(removeCommas(value)).toFixed(2);
    }else if(type == 'whole'){
        return parseInt(removeCommas(value));
    }
}

function insertCommas(value){
    value = value.split('.');
    if(value[1] !== undefined){
        return value[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + '.' + value[1];
    }else{
        return value[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
}

function removeCommas(value){
    return value.replace(/[^\d\.]/g,'');
}

function commaCount(value){
    var counter = 0;
    for(var i=0; i < value.length;i++){
        if(value.charAt(i) == ','){
            counter++;
        }
    }
    return counter;
}

function isCaretBeforeDecimal(caretPos, decimalPos){
    if(caretPos <= decimalPos){
        return true;
    }else if(caretPos > decimalPos){
        return false;
    }
}

function isSelected(){
    if(document.getSelection().toString() !== ''){
        return true;
    }else if(document.getSelection().toString === ''){
        return false;
    }
}

//blur
function completeDisplay(){
    this._internalVars.inputValue = this._internalVars.node.value;

    if(this._internalVars.node.value !== ''){
        this._internalVars.node.value = insertCommas(parsedValue(this._internalVars.inputValue, this._options.numType).toString());

        if(this._options.onCompleteFormat){
            this._options.onCompleteFormat(parsedValue(this._internalVars.node.value, this._options.numType));
        }
    }
}

//keydown-initial
function inputCharacters(e){
    this._internalVars.inputValue = this._internalVars.node.value;
    this._internalVars.caretLocation = this._internalVars.node.selectionStart;
    this._internalVars.decimalLocation = this._internalVars.node.value.indexOf('.');

    if(this._options.numType == 'decimal'){
        if(decimalExist(this._internalVars.inputValue)){
            this._internalVars.allowDecimal = false;
        }else{
            this._internalVars.allowDecimal = true;
        }

        if(decimalExist(this._internalVars.inputValue) && !isCaretBeforeDecimal(this._internalVars.caretLocation, this._internalVars.decimalLocation) && numOfCharAfterDecimal(this._internalVars.inputValue) == 2 && !isSelected()){
            this._internalVars.allowCharacters = false;
            this._internalVars.node.setAttribute('maxlength', this._internalVars.inputValue.length);
        }
        else if(decimalExist(this._internalVars.inputValue) && isCaretBeforeDecimal(this._internalVars.caretLocation, this._internalVars.decimalLocation) && numOfCharAfterDecimal(this._internalVars.inputValue) <= 2 || isSelected()){
            this._internalVars.allowCharacters = true;
            this._internalVars.node.removeAttribute('maxlength');
        }
        else{
            this._internalVars.allowCharacters = true;
            this._internalVars.node.removeAttribute('maxlength');
        }
    }else if(this._options.numType == 'whole'){
        this._internalVars.allowDecimal = false;
    }

}

//keyup-after
function inputAutoCommas(e){
    var keyCode = e.which || e.keyCode;
    this._internalVars.inputValue = this._internalVars.node.value;
    this._internalVars.caretLocation = this._internalVars.node.selectionStart;
    var beforeCommaCount = commaCount(this._internalVars.inputValue);

    if(keyCode != 9 && keyCode != 16 && !(keyCode >= 37 && keyCode <=40)) {
        this._internalVars.node.value = insertCommas(removeCommas(this._internalVars.inputValue));

        this._internalVars.inputValue = this._internalVars.node.value;

        if(this._options.numType == 'whole'){
            this._internalVars.node.value = this._internalVars.inputValue.replace('.', '');
        }

        var afterCommaCount = commaCount(this._internalVars.inputValue);

        if (beforeCommaCount != afterCommaCount) {
            this._internalVars.node.selectionStart = this._internalVars.caretLocation + 1;
            this._internalVars.node.selectionEnd = this._internalVars.caretLocation + 1;
            if (keyCode == 8) {
                this._internalVars.node.selectionStart = this._internalVars.caretLocation - 1;
                this._internalVars.node.selectionEnd = this._internalVars.caretLocation - 1;
            }
        } else {
            this._internalVars.node.selectionStart = this._internalVars.caretLocation;
            this._internalVars.node.selectionEnd = this._internalVars.caretLocation;
        }
    }
}

//keypress
function preventInput(event){
    if(!isCharacterValid(event, this._options.numType) || !this._internalVars.allowCharacters && event.which != 8){
        event.preventDefault();
    }
    if(!this._internalVars.allowDecimal && event.which == 46){
        event.preventDefault();
    }
    if((event.shiftKey) && event.which == 37 || event.which == 38){
        event.preventDefault();
    }
}

/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.keyDownHandler = inputCharacters.bind(this);
    this._internalVars.keyUpHandler = inputAutoCommas.bind(this);
    this._internalVars.keyPressHandler = preventInput.bind(this);
    this._internalVars.blurHandler = completeDisplay.bind(this);

    this._internalVars.inputValue = this._internalVars.node.value;
    this._internalVars.caretLocation = this._internalVars.node.selectionStart;
    this._internalVars.decimalLocation = this._internalVars.node.value.indexOf('.');
    this._internalVars.allowCharacters = true;
    this._internalVars.allowDecimal = true;
}

export default CurrencyInput;