//global vars
const html = document.getElementsByTagName('html')[0];

// Module Imports
import * as utils from './utils';

let appState = {

	mode			: null,
	isTouchDevice	: isTouchDevice(),

	size : {
		windowWidth : null,
		windowHeight : null
	},

	set windowSize(obj) {
		this.size.windowWidth = obj.width;
		this.size.windowHeight = obj.height;
		setMode(this.size.windowWidth);
	},

	get windowSize() {
		return this.size;
	},

};


/**
 * setMode()
 * Sets the appState mode depending on window side
 *
 * @param {Number} Window Width
 */
function setMode(w) {
	if(w >= 768){
		appState.mode = "desktop";
	}else{
		appState.mode = "mobile";
	}
}


/**
 * isTouchDevice()
 * Sets the appState mode depending on window side
 *
 * @return {Bool}
 */
function isTouchDevice() {
	return utils.hasClass(html, 'touch');
}


export default appState;
