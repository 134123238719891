//Require static assets
var Trianglify = require('trianglify');

//Module Imports
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",//a type or a value
        possibleValues: ["string", "object"],
        errorMessage: ["GDK BackgroundPattern : Content must be defined and set to a DOM selector or Node"]
    }
];

class BackgroundPattern {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     *  A reference to the html More Background Pattern node
     */
    constructor(options) {

        console.log('BackgroundPattern initialized');

        //SM=75 MED=125 LG=175

        this._internalVars = {
            node: null //used for current node
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            init.call(this);
        }
    }

    /**
    * destroy()
    * removes the node from the dom and any events attached
    */
    destroy(){
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
    
}

// Private Methods

/**
 * init()
 * Initializes the Trianglify pattern
 */
function init() {
   
    var pattern = Trianglify({
        variance: "0.7",
        cell_size: 175,
        x_colors: ["#ffffff","#dcdcdc", "#e6e6e6","#ffffff", "e8e8e8","f0f0f0", "#dcdcdc","#ffffff", "#f5f5f5","e8e8e8", "#ffffff", "#e6e6e6","#f5f5f5","#fafafa", "#ffffff"],
        width: 3000,
        height: 800
    });
    this._internalVars.node.appendChild(pattern.canvas());
}


/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }
}

export default BackgroundPattern;