/**
 * Created by u1d230 on 11/6/15.
 */

//Module Imports
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [{
    setting: 'content',
    isRequired: true,
    validate: 'type', //a type or a value
    possibleValues: ['string', 'object'],
    errorMessage: ['GDK EditableTableDeprecated : Content must be defined and set to a DOM selector or Node']
}];

class EditableTable {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     *  A reference to the editable table with edit buttons and fields node
     */
    constructor(options) {
        this._internalVars = {
            node: null, //used for current node
            editBtn: null
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.editBtn = this._internalVars.node.querySelectorAll('.table--editable-non-sortable .btn');

    this._internalVars.handler = toggleEdit.bind(this);
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    const eventName = UserAgentService._clickEventName();

    for(let i=0;i<this._internalVars.editBtn.length;i++){
        this._internalVars.editBtn[i].addEventListener(eventName, this._internalVars.handler);
    }
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();

    for(let i=0;i<this._internalVars.editBtn.length;i++){
        this._internalVars.editBtn[i].removeEventListener("click", this._internalVars.handler);
    }

}

function toggleEdit(e){
    var currentEditBtn = e.target;
    var parentTR = currentEditBtn.parentNode.parentNode;
    var childrenTD = parentTR.querySelectorAll('td');
    for(let i=0;i<childrenTD.length;i++){
        childrenTD[i].classList.toggle("td-edit-fields");
    }
}

export default EditableTable;