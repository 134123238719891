/**
 * Created by u1d230 on 7/13/15.
 */

//Module Imports
import baseComponent from '../baseComponent';

const validateSettings = [{
    setting: 'content',
    isRequired: true,
    validate: 'type', //a type or a value
    possibleValues: ['string', 'object'],
    errorMessage: ['GDK Alert : Content must be defined and set to a DOM selector or Node']
}];

class Alert {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     *  A reference to the html alert node
     */
    constructor(options) {
        this._internalVars = {
            node: null, //used for current node
            alertContentClass: "alert-content",
            alertContent: null,
            alertCloseBtnClass: "icon-close-20",
            alertCloseBtn: null
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.alertCloseBtn = this._internalVars.node.querySelectorAll('button.' + this._internalVars.alertCloseBtnClass);
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    for(let i=0;i<this._internalVars.alertCloseBtn.length;i++){
        this._internalVars.alertCloseBtn[i].addEventListener("click", removeAlert.bind(this, this._internalVars.alertCloseBtn[i]));
    }
}

function removeEvents(){
    for(let i=0;i<this._internalVars.alertCloseBtn.length;i++){
        this._internalVars.alertCloseBtn[i].removeEventListener("click", removeAlert.bind(this, this._internalVars.alertCloseBtn[i]));
    }
}


/**
 * removeAlert()
 * checks to remove Alert appropriately
 */
function removeAlert(e) {
    if (this._internalVars.node.children.length==1&&e.parentNode.parentNode.children.length==1) {
        fadeAndRemove(this._internalVars.node);
    }
    if (e.parentNode.parentNode.children.length==1) {
        fadeAndRemove(e.parentNode.parentNode.parentNode);
    }
    fadeAndRemove(e.parentNode);
}

/**
 * fade()
 * fade effect
 */
function fadeOutEffect(e) {
    let op = 1;  // initial opacity
    let timer = setInterval(function () {
        if (op <= 0.01){
            clearInterval(timer);
        }
        e.style.opacity = op;
        e.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, 15);
}

/**
 * removeChildCall
 * Instantiate removal of child element
 */
function removeChildCall(e) {
    e.parentNode.removeChild(e);
}

/**
 * fadeAndRemove()
 * Combine fade effect with delayed removal of element
 */
function fadeAndRemove(e){
    fadeOutEffect(e);
    setTimeout(removeChildCall, 500, e);
}

export default Alert;