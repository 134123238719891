//Require static assets
var Trianglify = require('trianglify');

//Module Imports
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",//a type or a value
        possibleValues: ["string", "object"],
        errorMessage: ["GDK BackgroundPatternPortfolioPage : Content must be defined and set to a DOM selector or Node"]
    }
];

class BackgroundPatternPortfolioPage {

    constructor(options) {

        console.log('BackgroundPatternPortfolioPage initialized');

        //SM=75 MED=125 LG=175

        this._internalVars = {
            node: null //used for current node
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            init.call(this);
        }
    }

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods

/**
 * init()
 * Initializes the Trianglify pattern
 */
function init() {

    if(this._internalVars.alertVerificationItems.length === 0) {
        alertHeight.call(this);
    }
    else {
       initialAlertDisplay.call(this);
    }

}

function trianglify() {
    console.log(this._internalVars.canvasHeight);
    var pattern = Trianglify({
        variance: "0.7",
        cell_size: 175,
        x_colors: ["#ffffff","#dcdcdc", "#e6e6e6","#ffffff", "e8e8e8","f0f0f0", "#dcdcdc","#ffffff", "#f5f5f5","e8e8e8", "#ffffff", "#e6e6e6","#f5f5f5","#fafafa", "#ffffff"],
        width: 3000,
        height: this._internalVars.canvasHeight
    });
    this._internalVars.node.appendChild(pattern.canvas());
    var canvas = document.getElementsByTagName('canvas');
    console.log(canvas);
    canvas[0].setAttribute('id', 'portfolioBackground');
    if (canvas[1]) {
        canvas[1].parentElement.removeChild(canvas[1]);
    }
}

function setCanvasHeight() {
    if(this._internalVars.alertVerificationItems.length === 0) {
        this._internalVars.canvasHeight = 220;
    }
    if(document.getElementById(this._internalVars.canvasID) === null) {

    }
    else {
        document.getElementById(this._internalVars.canvasID).parentNode.removeChild(document.getElementById(this._internalVars.canvasID));
    }
    this._internalVars.backgroundPatternHeightObject.style.height = this._internalVars.canvasHeight + "px";
    trianglify.call(this);

}

function initialAlertDisplay() {
    this._internalVars.alertActive = 0;
    this._internalVars.alertItems[this._internalVars.alertActive].classList.add('alert-active');
    if(this._internalVars.alertItems.length > 1) {
        this._internalVars.alertTotalObject.innerHTML = this._internalVars.alertItems.length;
        currentAlertState.call(this);
    } else {
        //hide carousel
        this._internalVars.carouselObject.style.display = 'none';
    }
    alertHeight.call(this);
    chevronState.call(this);
}

function chevronState () {
    let currentAlert = this._internalVars.alertActive + 1;

    if(currentAlert === 1) {
        //disable left arrow
        this._internalVars.arrowLeft.setAttribute('disabled', 'disabled');
        this._internalVars.arrowLeft.parentNode.classList.add('disabled');
        if (this._internalVars.alertItems.length === 2) {
            this._internalVars.arrowRight.removeAttribute('disabled');
            this._internalVars.arrowRight.parentNode.classList.remove('disabled');
        }
    } else if(currentAlert === this._internalVars.alertItems.length) {
        //disable right arrow
        if (this._internalVars.alertItems.length === 2) {
            this._internalVars.arrowLeft.removeAttribute('disabled');
            this._internalVars.arrowLeft.parentNode.classList.remove('disabled');
        }
        this._internalVars.arrowRight.setAttribute('disabled', 'disabled');
        this._internalVars.arrowRight.parentNode.classList.add('disabled');
    }
    else {
        //remove disabled classes/attributes
        this._internalVars.arrowLeft.removeAttribute('disabled');
        this._internalVars.arrowLeft.parentNode.classList.remove('disabled');
        this._internalVars.arrowRight.removeAttribute('disabled');
        this._internalVars.arrowRight.parentNode.classList.remove('disabled');
    }
}

function currentAlertState() {
    this._internalVars.alertCurrentObject.innerHTML = this._internalVars.alertActive + 1;
}

function alertHeight() {
    this._internalVars.canvasHeight = this._internalVars.backgroundPatternHeightObject.querySelector('.container').clientHeight;
    setCanvasHeight.call(this);
}

function setEvents() {
    this._internalVars.arrowRight.addEventListener("click", carouselSelection.bind(this));
    this._internalVars.arrowLeft.addEventListener("click", carouselSelection.bind(this));

    for(let i=0;i<this._internalVars.closeButtonObject.length;i++){
        this._internalVars.closeButtonObject[i].addEventListener("click", closeButton.bind(this, this._internalVars.closeButtonObject[i]));
    }

    window.addEventListener('resize', alertHeight.bind(this));
}

function closeButton() {
    let nextAlert = null,
        currentAlert = this._internalVars.alertActive + 1;

    if(currentAlert === this._internalVars.alertItems.length) {
        nextAlert = this._internalVars.alertActive - 1;
    } else {
        nextAlert = this._internalVars.alertActive;
    }

    this._internalVars.alertItems[this._internalVars.alertActive].classList.remove('alert-active');
    this._internalVars.alertItems[this._internalVars.alertActive].classList.remove('alert-importance');
    this._internalVars.alertItems = document.querySelectorAll('.' + this._internalVars.alertItemsClass);

    if(this._internalVars.alertItems.length > 0) {
        this._internalVars.alertItems[nextAlert].classList.add('alert-active');
        this._internalVars.alertActive = [].indexOf.call(this._internalVars.alertItems, document.querySelector('.' + this._internalVars.currentAlertClass));

        if(this._internalVars.alertItems.length === 1) {
            this._internalVars.carouselObject.style.display = 'none';
        } else {
            this._internalVars.alertTotalObject.innerHTML = this._internalVars.alertItems.length;
            chevronState.call(this);
            currentAlertState.call(this);
        }
    } else {
        this._internalVars.alertVerificationItems[0].classList.remove('portfolio-alerts');
    }
    alertHeight.call(this);

}

function carouselSelection(e) {
    e.preventDefault();

    let nextAlert = null;

    this._internalVars.alertItems[this._internalVars.alertActive].classList.remove('alert-active');

    if(e.target.id === 'arrow-right') {
        nextAlert = this._internalVars.alertActive + 1;
        this._internalVars.alertActive = nextAlert;
    }
    else{
        nextAlert = this._internalVars.alertActive - 1;
        this._internalVars.alertActive = nextAlert;
    }

    this._internalVars.alertItems[this._internalVars.alertActive].classList.add('alert-active');

    alertHeight.call(this);
    chevronState.call(this);
    currentAlertState.call(this);
}


/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.alertVerificationClass = 'portfolio-alerts';
    this._internalVars.alertVerificationItems = document.getElementsByClassName(this._internalVars.alertVerificationClass);

    this._internalVars.backgroundPatternHeightID = "background-pattern-portfolio-page";
    this._internalVars.backgroundPatternHeightObject = document.getElementById(this._internalVars.backgroundPatternHeightID);
    this._internalVars.canvasHeight = null;


    this._internalVars.alertContainerID = 'portfolio-alert-container';
    this._internalVars.alertContainerObject = document.getElementById(this._internalVars.alertContainerID);

    this._internalVars.alertItemsClass = 'alert-importance';
    this._internalVars.alertItems = document.querySelectorAll('.' + this._internalVars.alertItemsClass);

    this._internalVars.currentAlertClass = 'alert-active';
    this._internalVars.currentAlertObject = document.querySelector('.' + this._internalVars.currentAlertClass);

    this._internalVars.alertActive = null;

    this._internalVars.alertCurrentID = 'alert-current';
    this._internalVars.alertTotalID = 'alert-total';
    this._internalVars.alertCurrentObject = document.getElementById(this._internalVars.alertCurrentID);
    this._internalVars.alertTotalObject = document.getElementById(this._internalVars.alertTotalID);

    this._internalVars.arrowRightID = 'arrow-right';
    this._internalVars.arrowRight = document.getElementById(this._internalVars.arrowRightID);

    this._internalVars.arrowLeftID = 'arrow-left';
    this._internalVars.arrowLeft = document.getElementById(this._internalVars.arrowLeftID);

    this._internalVars.carouselClass = 'carousel';
    this._internalVars.carouselObject = document.querySelector('.' + this._internalVars.carouselClass);

    this._internalVars.closeButtonClass = 'icon-close-20';

    this._internalVars.alertContainerID = 'portfolio-alert-container';
    this._internalVars.alertContainerObject = document.getElementById(this._internalVars.alertContainerID);
    this._internalVars.closeButtonObject =  this._internalVars.alertContainerObject.querySelectorAll('.'+this._internalVars.closeButtonClass);

    this._internalVars.canvasID = 'portfolioBackground';
}

export default BackgroundPatternPortfolioPage;