//Require static assets
import appState from '../appState';

//Module Imports
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK CommonQuestionsSquares : Content must be defined and set to a DOM selector or Node"]
    }
];

class CommonQuestionsSquares{
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     *  A reference to the common questions squares node
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for content item
            individualBoxes: null
        };

        //options with defaults set
        this._defaults = {

        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);

            let allSquares = this._internalVars.node.querySelectorAll('.square');
            Array.prototype.forEach.call(allSquares, function(el, i){
                if(!el.classList.contains('square-link'))
                el.setAttribute('tabindex', '0');
            });
        }
    }

    //Public Methods

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    const eventName = UserAgentService._clickEventName();

    console.log( this._internalVars.individualBoxes);

    for (var j = 0; j < this._internalVars.individualBoxes.length - 1; j++) {
        this._internalVars.individualBoxes[j].addEventListener('mouseover', removeStyleAttr.bind(this, this._internalVars.individualBoxes[j]));
        this._internalVars.individualBoxes[j].addEventListener(eventName, openPanel.bind(this, this._internalVars.individualBoxes[j]));
    }

    for(var h = 0; h < this._internalVars.node.querySelectorAll('a').length; h++ ){
       this._internalVars.anchorArray[h].addEventListener('focus', linkFocus.bind(this, this._internalVars.anchorArray[h]));
       this._internalVars.anchorArray[h].addEventListener('blur', linkUnfocus.bind(this, this._internalVars.anchorArray[h]));
    }
}

function linkFocus(e){
    var squareParent = e.parentNode.parentNode;
    squareParent.classList.add('flip-back');
    squareParent.previousElementSibling.classList.add('flip-front');
}

function linkUnfocus(e){
    var squareParent = e.parentNode.parentNode;
    squareParent.classList.remove('flip-back');
    squareParent.previousElementSibling.classList.remove('flip-front');
}

function removeStyleAttr(e){
    if(appState.mode !== "mobile") {
        var el = e.querySelector('.back');
        el.removeAttribute('style');
    }
}


function openPanel(e){
    var el = e.querySelector('.back');
    if(appState.mode === "mobile"){
        e.classList.toggle('open');
        if(e.classList.contains('open')){
            el.style.display = "block";
            let paragraph = el.querySelector("p");
            el.style.maxHeight = outerHeight.call(this,paragraph) + "px";
        }else{
            el.removeAttribute('style');
            el.style.display = "block";
            el.style.maxHeight="0px";
        }
    }else{
        el.removeAttribute('style');
    }
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();

    for (var j = 0; j < this._internalVars.individualBoxes.length - 1; j++) {
        this._internalVars.individualBoxes[j].removeEventListener('mouseover', removeStyleAttr.bind(this, this._internalVars.individualBoxes[j]));
        this._internalVars.individualBoxes[j].removeEventListener(eventName, openPanel.bind(this, this._internalVars.individualBoxes[j]));
    }


}


/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.individualBoxes = this._internalVars.node.children;
    this._internalVars.anchorArray = this._internalVars.node.querySelectorAll('a');
}

/**
 * outerHeight()
 * used to get the height of an element that is the same across all browsers
 *
 * @param  {Object} node    DOM node
 * @return {number}
 */
function outerHeight(el) {
    let height = el.offsetHeight;
    let style = getComputedStyle(el);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
}

export default CommonQuestionsSquares;