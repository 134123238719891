class CharacterTypeService {
    static _getCharacterType(event) {
        let keyCode = event.which || event.keyCode;

        if(event) {

            // check if character keyCode is 0-9
            if(keyCode >= 48 && keyCode <= 57 || keyCode >= 96 && keyCode <= 105) {

                if(event.key) {
                    if (event.key === '1' || event.key === '2' || event.key === '3' || event.key === '4' || event.key === '5' || event.key === '6' || event.key === '7' || event.key === '8' || event.key === '9' || event.key === '0') {
                        return 'number';
                    } else {
                        return 'special character';
                    }
                } else {
                    return 'n/a';
                }

            } else {

                // Check if character is backspace
                if(keyCode === 8) {
                    return 'backspace';
                } else

                // Check if character is tab
                if(keyCode === 9) {
                    return 'tab';
                } else

                // Check if character is enter
                if(keyCode === 13) {
                    return 'enter';
                } else

                // Check if character is shift
                // if (keyCode === 16) {
                //     return 'shift';
                // }

                // Check for additional special characters
                if (keyCode >= 186 && keyCode <= 192 || keyCode >= 219 && keyCode <= 222) {
                    return 'special character';
                } else

                // Check if character is a letter
                if(keyCode >= 65 && keyCode <= 90) {

                    // Check for key functions
                    //
                    // Minimize
                    if (keyCode === 77 && event.ctrlKey === true || keyCode === 77 && event.metaKey === true) {
                        return 'minimize';
                    } else

                    // Copy
                    if (keyCode === 67 && event.ctrlKey === true || keyCode === 67 && event.metaKey === true) {
                        return 'copy';
                    } else

                    // Paste
                    if (keyCode === 80 && event.ctrlKey === true || keyCode === 80 && event.metaKey === true) {
                        return 'paste';
                    } else {
                        return 'letter';
                    }
                } else

                // Check if character is delete
                if(keyCode === 46) {
                    return 'delete';
                } else

                // Check if character is home
                if (keyCode === 36) {
                    return 'home';
                } else

                // Check if character is end
                if (keyCode === 35) {
                    return 'end';
                } else

                // Check if character is arrow key
                if(keyCode >= 37 && keyCode <= 40) {
                    switch (keyCode) {
                        case 37:
                            return 'left arrow';
                        case 38:
                            return 'up arrow';
                        case 39:
                            return 'right arrow';
                        case 40:
                            return 'down arrow';
                    }
                }

                else {
                    //return 'not categorized';
                }
            }
        }
    }
}

export default CharacterTypeService;