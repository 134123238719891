//Require static assets

//Module Imports
import baseComponent from '../baseComponent';

const validateSettings = [
  {
    setting: "content",
    isRequired: true,
    validate: "type",//a type or a value
    possibleValues: ["string", "object"],
    errorMessage: ["GDK MultipleSelectBox : Content must be defined and set to a DOM selector or Node"]
  },
  {
    setting: "config",
    isRequired: false,
    validate: "type",//a type or a value
    possibleValues: ["object"],
    errorMessage: ["GDK MultipleSelectBox : config may be defined and set as an object"]
  },
  {
    setting: "initialOptions",
    isRequired: false,
    validate: "type",//a type or a value
    possibleValues: ["object", "string"],
    errorMessage: ["GDK MultipleSelectBox : initialOptions may be defined and set as an array"]
  },
  {
    setting: "multiSelectChange",
    isRequired: false,
    validate: "type",//a type or a value
    possibleValues: ["function"],
    errorMessage: ["GDK MultipleSelectBox : multiSelectChange may be defined and set as a function"]
  }

];

/**
 * MultipleSelectBox class
 */
class MultipleSelectBox {

  /**
   * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
   * @param {string|Object} content
   *  A reference to the multiple select box node
   *
   *  @param {Object} [initialOptions]
   *  An array containing strings corresponding to 'value' attributes or numbers referencing the index of the select options
   *
   *  @param {function} [multiSelectChange]
   *  A callback function that is triggered when an option is added or removed from the Multi-Select Box
   */
  constructor(options) {

    this._internalVars = {
      node: null,//used for current node
      closeBtn: null
    };

    //options with defaults set
    this._defaults = {
      autoShow: false,
      overlayShouldCloseModal: true,
      hideCloseButton: false,
      config: {
        '.chosen-select': {},
        '.chosen-select-deselect': {allow_single_deselect: true},
        '.chosen-select-no-single': {disable_search_threshold: 10},
        '.chosen-select-no-results': {no_results_text: 'Oops, nothing found!'},
        '.chosen-select-width': {width: "auto"}
      }
    };

    // Create options by extending defaults with the passed in arugments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    //if the required options are valid set up the environment
    if (baseComponent.validateSettings(this._options, validateSettings)) {
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);
      init.call(this);
      setEvents.call(this);
    }

  }

  //Public Methods

  /**
   * sets the initial selected options
   * @param {Object} array Array containing the index or option text of the items to be pre-selected
   */
  setInitialSelectionOptions(array) {
    setInitialSelections.call(this, array);
  }

  /**
   * Returns the current selected options
   * @returns {Array} containing the selected options
   */
  activeSelections() {
    return this._internalVars.selectedItems;
  }

  /**
   * removes the node from the dom and any events attached
   */
  destroy(){
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (var variableKey in this){
      if (this.hasOwnProperty(variableKey)){
        delete this[variableKey];
      }
    }
  }

}

function init() {
  if (this._options.initialOptions) {
    let array = this._options.initialOptions;

    setInitialSelections.call(this, array);
  }

  for (let selector in this._options.config) {
    $(selector).chosen(this._options.config[selector]);
  }
}

function setEvents() {
  $('#'+this._internalVars.node.id).chosen().on('change', updateValues.bind(this));
}


/**
 * Sets the initial values pragmatically
 * @param {array} array Array containing numbers or strings referencing options to be set by default
 */
function setInitialSelections(array) {

  let initialValues = [];

  Array.prototype.forEach.call(array, (item)=>{
    if (typeof item === "string") {
      Array.prototype.forEach.call(this._internalVars.node.options, (ele)=>{
        if (item === ele.value) {
          initialValues.push(item);
        }
      });
    } else if (typeof item === "number") {
      if (item >= 0 && item <= this._internalVars.node.options.length) {
        if (item - Math.floor(item) === 0) {
          let value = this._internalVars.node.options[item].value;
          initialValues.push(value);
        }
      }
    }
  });

  $('#'+this._internalVars.node.id).val(initialValues).trigger('chosen:updated');

  updateValues.call(this);
}

/**
 * creates an array of the chosen values
 */
function updateValues() {

  let selectBox = $('#'+this._internalVars.node.id),
      empty;

  this._internalVars.selectedItems = [];

  if (selectBox.val() !== null) {
    Array.prototype.forEach.call(selectBox.val(), (element)=>{
      Array.prototype.forEach.call(this._internalVars.node.options, (el)=>{
        if (element === el.value) {
          this._internalVars.selectedItems.push(el);
        }
      });
    });
    empty = false;
  } else {
    empty = true;
  }

  if(this._options.multiSelectChange) {
    this._options.multiSelectChange(this._internalVars.selectedItems, empty);
  }
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
  //determine the type of content passed in
  if (this._internalVars.contentType === 'string') {
    this._internalVars.node = document.querySelector(this._options.content);
  } else if (this._internalVars.contentType === 'domNode') {
    this._internalVars.node = this._options.content;
  }

  this._internalVars.nodeParent = this._internalVars.node.parentNode;
}

export default MultipleSelectBox;