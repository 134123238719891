/**
 * hasClass()
 * Checks wether element contains a class
 * This accounts for the fact that element might contain multiple class names separated by space.
 *
 * @param {Dom Element} element
 * @param {String} className we are checking
 * @return {Boolean}
 */

 //TODO check the best way to do this
function hasClass(element, className) {
    return element.classList.contains(className);
}


/**
 * getParentByTagName()
 * Get parent node for given tagname
 *
 * @param  {Object} node    DOM node
 * @param  {String} tagname HTML tagName
 * @return {Object}         Parent node
 */
function getParentByTagName(node, tagname) {
	var parent;
	if (node === null || tagname === '') return;
	parent  = node.parentNode;
	tagname = tagname.toUpperCase();

	while (parent.tagName !== "HTML") {
		if (parent.tagName === tagname) {
			return parent;
		}
		parent = parent.parentNode;
	}

	return parent;
}

function isChild(parent, child) {
    var node = child.parentElement;
    while (node !== null) {
        if (node.classList.contains(parent)) {
            return true;
        }
        node = node.parentElement;
    }
    return false;
}

/**
 * scrollToTop()
 * Used to animate the scroll position back to the top of the page
 */
function scrollToTop() {

    const   scrollHeight = window.scrollY,
    		scrollDuration = 500,
            scrollStep = Math.PI / ( scrollDuration / 15 ),
            cosParameter = scrollHeight / 2;

    let     scrollCount = 0,
            scrollMargin;

    requestAnimationFrame(step);

	console.log(scrollStep);

    function step () {
        setTimeout(function() {
            if ( window.scrollY !== 0 ) {
                    requestAnimationFrame(step);
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
            }
        }, 15 );
    }
}

export { hasClass, getParentByTagName, isChild };
