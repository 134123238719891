//Require static assets

//Module Imports
import baseComponent from '../baseComponent';
import characterType from '../services/CharacterTypeService';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Credit Card : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "alternateCard",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["object"],
        errorMessage            :   ["GDK Credit Card : alternateCard must be defined as an object"]
    }
];

/**
 * CreditCard Class
 */
class CreditCard{
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the div form field containing the credit card input node
     *
     */

    constructor(options) {

        this._internalVars = {

            creditCards : {
                americanExpress : {
                    prefixes : ['34', '37'],
                    cardFormatArray : [4,6,5],
                },
                visa : {
                    prefixes : ['4'],
                    cardFormatArray : [4,4,4,4],
                },
                mastercard : {
                    prefixes : ['2221-2720', '51-55'],
                    cardFormatArray : [4,4,4,4],
                },
                discover : {
                    prefixes : ['6011', '64', '65'],
                    cardFormatArray : [4,4,4,4],
                }
            },

            maskEntry: maskEntry.bind(this)
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            validateAlternateCards.call(this);
        }
    }

    //Public Methods

    /**
     * getCardType()
     * returns the card type
     * @returns {*|string}
     */
    getCardType() {
        switch (this._internalVars.creditCardType) {
            case 'default':
                this._internalVars.creditCardType = 'unknown';
                break;
            case undefined:
                this._internalVars.creditCardType = 'unknown';
                break;
        }
        return this._internalVars.creditCardType;
    }

    /**
     * getCardNumber()
     * returns the card input value without formatting
     * @returns {*|string}
     */
    getCardNumber() {
        if (this._internalVars.maskSet === true) {
            return this._internalVars.creditCardInputHidden.value.replace(/ /g, '');
        } else {
            return this._internalVars.creditCardInput.value.replace(/ /g, '');
        }
    }

    /**
     * setCardNumber(cardNumber)
     * sets the card number
     * @param {number} cardNumber
     * The card number to be populated in the input field
     */
    setCardNumber(cardNumber) {
        if(cardNumber && typeof cardNumber === "number") {
            this._internalVars.creditCardInput.value = cardNumber;
            change.call(this);
            maskEntry.call(this);
        }
    }

    /**
     * isComplete()
     * returns boolean indicating a complete and recognized value
     * @returns {boolean}
     */
    isComplete() {
        this.getCardType();
        if (this._internalVars.creditCardType !== 'unknown' ) {
            if (this._internalVars.creditCardInput.value.length === parseInt(this._internalVars.creditCardInput.getAttribute('maxlength'))) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    this._internalVars.creditCardInput.addEventListener('paste', paste.bind(this));
    this._internalVars.creditCardInput.addEventListener('keydown', validateKeyPress.bind(this));
    this._internalVars.creditCardInput.addEventListener('keyup', checkValue.bind(this));
    this._internalVars.creditCardInput.addEventListener('input', change.bind(this));
    this._internalVars.creditCardInput.addEventListener('blur', this._internalVars.maskEntry);
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    this._internalVars.creditCardInput.removeEventListener('paste', paste.bind(this));
    this._internalVars.creditCardInput.removeEventListener('keydown', validateKeyPress.bind(this));
    this._internalVars.creditCardInput.removeEventListener('keyup', checkValue.bind(this));
    this._internalVars.creditCardInput.removeEventListener('input', change.bind(this));
    this._internalVars.creditCardInput.removeEventListener('blur', this._internalVars.maskEntry);
}

function parseRange(range) {
    let hyphenPosition = range.indexOf('-');
    let beginningPoint = range.substring(0, hyphenPosition);
    let endPoint = range.substring(hyphenPosition + 1, range.length);
    return {
        "hyphenPosition": hyphenPosition,
        "beginningPoint": beginningPoint,
        "endPoint": endPoint
    };
}

/**
 * validateAlternateCards()
 * validates the alternate card object and properties
 */
function validateAlternateCards() {
    //check if alternate cards have been set
    if(this._options.alternateCard) {
        //Loop through alternateCard object
        for(var alternateCard in this._options.alternateCard) {
            //check if alternateCard object has prefixes & cardFormatArray properties
            if(this._options.alternateCard[alternateCard].prefixes && this._options.alternateCard[alternateCard].cardFormatArray) {
                // Loop through prefixes property
                for(var cardDigits in this._options.alternateCard[alternateCard].prefixes) {
                    //check if values are the correct type
                    if (typeof this._options.alternateCard[alternateCard].prefixes[cardDigits] !== 'string') {
                        this._options.alternateCard[alternateCard].valid = false;
                    } else {
                        if(this._options.alternateCard[alternateCard].prefixes[cardDigits].indexOf('-') >= 0) {
                            let parsedRange = parseRange.call(this, this._options.alternateCard[alternateCard].prefixes[cardDigits]);
                            if((parsedRange.beginningPoint > 0) === false || (parsedRange.endPoint > 0) === false) {
                                this._options.alternateCard[alternateCard].valid = false;
                            }
                        } else {
                            if ((this._options.alternateCard[alternateCard].prefixes[cardDigits] > 0) === false) {
                                this._options.alternateCard[alternateCard].valid = false;
                            }
                        }
                    }
                }
                // Loop through cardFormatArray property
                for (var formatArray in this._options.alternateCard[alternateCard].cardFormatArray) {
                    // check if value is the correct type
                    if (typeof this._options.alternateCard[alternateCard].cardFormatArray[formatArray] !== 'number') {
                        this._options.alternateCard[alternateCard].valid = false;
                    } else if (this._options.alternateCard[alternateCard].cardFormatArray[formatArray] < 0) {
                        this._options.alternateCard[alternateCard].valid = false;
                    }
                }
            } else {
                this._options.alternateCard[alternateCard].valid = false;
            }
            // Set valid status if not defined as false
            if(this._options.alternateCard[alternateCard].valid === undefined) {
                this._options.alternateCard[alternateCard].valid = true;
            }
        }
    }
}

/**
 * paste(event)
 * @param event
 * removes non-numeric characters and formats numeric characters
 */
function paste(event) {
    let currentLength = this._internalVars.creditCardInput.value.length,
        //currentLengthEndSpace,
        endLength,
        endLengthEndSpace,
        caretPos = this._internalVars.creditCardInput.selectionStart,
        pasteLength,
        spaces = 0;
    //allow time for value to be recognized
    var delay = setTimeout(pasteComplete.bind(this), 100);
    function pasteComplete() {
        //remove non-numeric characters
        this._internalVars.creditCardInput.value = this._internalVars.creditCardInput.value.replace(/\D/g,'');
        change.call(this);
        endLength = this._internalVars.creditCardInput.value.length;

        for(var ind = 0; ind < this._internalVars.sequenceArray.length; ind++) {
            if (endLength === this._internalVars.sequenceArray[ind] + (ind + 1)) {
                endLengthEndSpace = true;
                break;
            } else {
                endLengthEndSpace = false;
            }
        }
        if (endLengthEndSpace === true) {
            endLength = endLength - 1;
        }
        pasteLength = endLength - currentLength;
        for(var index = 0; index < this._internalVars.sequenceArray.length; index++) {
            if (caretPos <= this._internalVars.sequenceArray[index] + index) {
                if (caretPos + pasteLength >= this._internalVars.sequenceArray[index] + index) {
                    spaces = spaces + 1;
                }
            }
        }

        let position = caretPos + pasteLength + spaces;

        //set cursor position
        this._internalVars.creditCardInput.setSelectionRange(position, position);
    }
}

/**
 * validateKeyPress(event)
 * @param event
 * restrict non-numeric characters
 */
function validateKeyPress(event) {
    let isValidCharacter = false;
    const validCharacterTypes = ['number', 'tab', 'enter', 'minimize', 'copy', 'paste', 'delete', 'home', 'end', 'left arrow', 'up arrow', 'right arrow', 'down arrow', 'backspace'];

    Array.prototype.forEach.call(validCharacterTypes, (type) => {
        if(characterType._getCharacterType(event) === type) {
            isValidCharacter = true;
        }
    });
    if(characterType._getCharacterType(event) === 'n/a') {
        let entryArray = this._internalVars.creditCardInput.value.split('');
        let convertedArray = [];

        Array.prototype.forEach.call(entryArray, (el, i)=> {
            if (el === '0' || el === '1' || el === '2' || el === '3' || el === '4' || el === '5' || el === '6' || el === '7' || el === '8' || el === '9') {} else {
                convertedArray.push(el);
            }
        });

        let entry = convertedArray.join('');
        this._internalVars.creditCardInput.value = entry;

    }

    if(isValidCharacter === false) {
        event.preventDefault();
    }

    if (isValidCharacter === true && characterType._getCharacterType(event) !== 'tab') {
        if (this._internalVars.maskSet === true) {
            // unmask field
            $(this._internalVars.creditCardInput).unmask();

            //reset values
            this._internalVars.creditCardInput.value = '';
            this._internalVars.creditCardImageField.setAttribute('class', 'credit-card-image');
            this._internalVars.creditCardType = 'default';
            this._internalVars.maskSet = false;
        }
    }
}

/**
 * checkValue(event)
 * @param event
 * set card properties, image, and format
 */
function checkValue(event) {
    let keyCode = event.which || event.keyCode;

    if(keyCode === 8) {
        setCardFormat.call(this, keyCode);
    }
}

/**
 * change()
 * Triggers function when input value changes
 */
function change() {
    setCardProperties.call(this);
    setCardImage.call(this);
    setCardFormat.call(this);
}

/**
 * setCardProperties()
 * set card type/format etc.
 */
function setCardProperties() {
    // Set variable identifying a matching card
    let cardIsSet = false;
    let inputValue = this._internalVars.creditCardInput.value.replace(/ /g, '');

    // loop through credit cards object
    for (var key in this._internalVars.creditCards) {
        if(this._internalVars.creditCards.hasOwnProperty(key)) {
            //loop through the prefixes array inside credit card object
            for(var digits in this._internalVars.creditCards[key].prefixes) {
                //Set properties if entry matches valid digits
                if(this._internalVars.creditCards[key].prefixes[digits].indexOf('-') >= 0) {
                    let parsedRange = parseRange.call(this, this._internalVars.creditCards[key].prefixes[digits]);
                    if(inputValue.substring(0, parsedRange.hyphenPosition) >= parsedRange.beginningPoint && inputValue.substring(0, parsedRange.hyphenPosition) <= parsedRange.endPoint) {
                        this._internalVars.creditCardType = key;
                        this._internalVars.cardFormatArray = this._internalVars.creditCards[key].cardFormatArray;
                        cardIsSet = true;
                    }
                } else {
                    if(inputValue.substring(0,this._internalVars.creditCards[key].prefixes[digits].length) === this._internalVars.creditCards[key].prefixes[digits]) {
                        this._internalVars.creditCardType = key;
                        this._internalVars.cardFormatArray = this._internalVars.creditCards[key].cardFormatArray;
                        cardIsSet = true;
                    }
                }
            }
        }
    }

    if(this._options.alternateCard) {
        //Loop through alternateCard setting object
        for(var alternateCard in this._options.alternateCard) {
            if (this._options.alternateCard[alternateCard].valid === true) {
                for (var cardDigits in this._options.alternateCard[alternateCard].prefixes) {
                    if(this._options.alternateCard[alternateCard].prefixes[cardDigits].indexOf('-') >= 0) {
                        let parsedRange = parseRange.call(this, this._options.alternateCard[alternateCard].prefixes[cardDigits]);
                        if(inputValue.substring(0, parsedRange.hyphenPosition) >= parsedRange.beginningPoint && inputValue.substring(0, parsedRange.hyphenPosition) <= parsedRange.endPoint) {
                            this._internalVars.creditCardType = alternateCard;
                            this._internalVars.cardFormatArray = this._options.alternateCard[alternateCard].cardFormatArray;
                            cardIsSet = true;
                        }
                    } else {
                        if(inputValue.substring(0,this._options.alternateCard[alternateCard].prefixes[cardDigits].length) === this._options.alternateCard[alternateCard].prefixes[cardDigits]) {
                            this._internalVars.creditCardType = alternateCard;
                            this._internalVars.cardFormatArray = this._options.alternateCard[alternateCard].cardFormatArray;
                            cardIsSet = true;
                        }
                    }
                }
            }
        }
    }

    //Set default properties if entry does not match valid digits
    if(cardIsSet === false) {
        this._internalVars.creditCardType = 'default';
        this._internalVars.creditCardInput.removeAttribute('maxlength');
    } else {
        //Set sequence array based on card format
        this._internalVars.sequenceArray = [];

        let sequenceNumber = 0;
        Array.prototype.forEach.call(this._internalVars.cardFormatArray, (formatNumber)=> {
            sequenceNumber += formatNumber;
            this._internalVars.sequenceArray.push(sequenceNumber);
            this._internalVars.cardMaxLenth = sequenceNumber + this._internalVars.cardFormatArray.length - 1;
        });
    }
}

/**
 * setCardImage()
 * sets the card type image on the view
 */
function setCardImage() {
    if(this._internalVars.creditCardType !== 'default') {
        let newCardType = false;
        if (this._options.alternateCard) {
            for (var type in this._options.alternateCard) {
                if (this._internalVars.creditCardType === type) {
                    newCardType = true;
                }
            }
        }
        if (newCardType === true) {
            this._internalVars.creditCardImageField.setAttribute('class', 'credit-card-image');
        } else {
            this._internalVars.creditCardImageField.setAttribute('class', 'credit-card-image ' + this._internalVars.creditCardType);
        }
    } else {
        this._internalVars.creditCardImageField.setAttribute('class', 'credit-card-image');
    }
}

/**
 * setCardFormat(key)
 * @param key
 * formats the entry based on the card type
 */
function setCardFormat(key) {
    //Set variables for cursor position
    let caretPos = this._internalVars.creditCardInput.selectionStart;
    let cursorPosition = caretPos;

    if (this._internalVars.creditCardType === 'default') {
        this._internalVars.creditCardInput.value = this._internalVars.creditCardInput.value.replace(/ /g, '');
    } else {
        //Set max length based on card type
        this._internalVars.creditCardInput.setAttribute('maxlength', this._internalVars.cardMaxLenth);

        //remove next available number if backspacing over a space
        if(key === 8) {
            for(var b = 0; b < this._internalVars.sequenceArray.length; b++) {
                if(caretPos === this._internalVars.sequenceArray[b] + b) {
                    let first = this._internalVars.creditCardInput.value.slice(0, caretPos-1);
                    let second = this._internalVars.creditCardInput.value.slice(caretPos, this._internalVars.creditCardInput.value.length);
                    this._internalVars.creditCardInput.value = first + second;
                    cursorPosition = caretPos - 1;
                }
            }
        }

        //Set variable containing input value and previous value without spaces
        let inputValue = this._internalVars.creditCardInput.value.replace(/ /g, '');
        this._internalVars.creditCardInputValue = inputValue;
        let prevInputValue = this._internalVars.creditCardInputPreviousValue.replace(/ /g, '');
        let difference = inputValue.length - prevInputValue.length;

        let newValueArray = [];
        for(var v = 0; v < this._internalVars.sequenceArray.length; v++) {
            if(inputValue.length >= this._internalVars.sequenceArray[v]-this._internalVars.cardFormatArray[v]) {
                newValueArray.push(inputValue.substring(this._internalVars.sequenceArray[v]-this._internalVars.cardFormatArray[v],this._internalVars.sequenceArray[v]));
            }
        }

        //check if multiple keys pressed
        if (key !== 8) {
            if(this._internalVars.creditCardInputPreviousValue.length + 1 < this._internalVars.creditCardInput.value.length) {
                for(var i = 0; i < this._internalVars.sequenceArray.length; i++) {
                    if(caretPos - difference < this._internalVars.sequenceArray[i] + (i + 1) && caretPos > this._internalVars.sequenceArray[i] + (i + 1)) {
                        cursorPosition = caretPos + 1;
                    }
                }
            }
        }

        //combine value arrays with a space
        let newValue = newValueArray.join(' ');
        this._internalVars.creditCardInput.value = newValue;

        //update cursor position and/or add a space if a number is pressed
        if(key !== 8) {
            for(var s = 0; s < this._internalVars.sequenceArray.length; s++) {
                if(caretPos + (difference - 1) > this._internalVars.sequenceArray[s] + (s + 1) && caretPos <= this._internalVars.sequenceArray[s] + (s + 1)) {
                    cursorPosition = caretPos + 1;
                }
                if(s + 1 !== this._internalVars.cardFormatArray.length && caretPos === this._internalVars.sequenceArray[s] + s && this._internalVars.creditCardInputPreviousValue.length !== this._internalVars.creditCardInput.value.length) {
                    cursorPosition = caretPos + 1;
                    if(caretPos === this._internalVars.creditCardInput.value.length) {
                        this._internalVars.creditCardInput.value = this._internalVars.creditCardInput.value + ' ';
                    }
                }
            }
        }

        this._internalVars.creditCardInputPreviousValue = this._internalVars.creditCardInput.value;
    }
    this._internalVars.creditCardInput.setSelectionRange(cursorPosition, cursorPosition);
}

function maskEntry() {
    let unmaskedCharacterLength = 4,
        hasSpace = 0,
        cardNumber = this.getCardNumber();

    //check if value has 5 or more letters
    if (cardNumber !== null && cardNumber.length > unmaskedCharacterLength) {
        // create mask format
        let maskArray = [];

        // loop through field value
        for (let i = 0; i < this._internalVars.creditCardInput.value.length; i++) {
            let num = this._internalVars.creditCardInput.value.charAt(i);

            if (num === ' ' && i === this._internalVars.creditCardInput.value.length - 1) {}
            else if (num === ' ') {
                maskArray.push(' ');
                if(this._internalVars.creditCardInput.value.length - (i + 1) < unmaskedCharacterLength + 1) {
                    hasSpace = 1;
                }
            } else {
                maskArray.push('9');
            }
        }

        // create single string mask
        let mask = maskArray.join('');

        //set mask character length and apply mask
        let maskedCharsLength = cardNumber.length - unmaskedCharacterLength - hasSpace;
        $(this._internalVars.creditCardInput).maskSSN(mask, {maskedChar:'•', maskedCharsLength: maskedCharsLength});
        this._internalVars.maskSet = true;
    }
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.creditCardField = this._internalVars.node;
    this._internalVars.creditCardClass = 'credit-card';
    this._internalVars.creditCardInput = this._internalVars.creditCardField.querySelector('.' + this._internalVars.creditCardClass);
    this._internalVars.creditCardInputHidden = this._internalVars.creditCardField.querySelector('.secureInput');
    this._internalVars.creditCardImageClass = 'credit-card-image';
    this._internalVars.creditCardImageField = this._internalVars.creditCardField.querySelector('.' + this._internalVars.creditCardImageClass);

    this._internalVars.creditCardInputPreviousValue = this._internalVars.creditCardInput.value;
    this._internalVars.creditCardInputValue = null;

    this._internalVars.maskSet = false;
}

export default CreditCard;