//Module Imports
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

var interactjs = require('interact.js');

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Time Input : Time Input must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "segmentedControlVar",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string"],
        errorMessage            :   ["GDK Time Input : segmentedControlVar must be defined as a string"]
    },
    {
        setting                 :   "callBackOnTimeChange",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK Time Input : callBackOnTimeChange must be a defined and set function"]
    },
    {
        setting                 :   "setInitialTime",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["string"],
        errorMessage            :   ["GDK Time Input : setInitialTime must be defined as a string"]
    }
];

/**
 * TimeInput Class
 */
class TimeInput {

    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string|Object} content
     * A reference to the time input component node
     *
     *
     * @param {string} segmentedControlVar
     * A name for the segmented control GDK Object
     *
     *
     * @param {function} [callBackOnTimeChange]
     * A callback function triggered when the time has changed
     *
     * @param {string} [setInitialTime]
     * A string that will populate the time input field and adjust the segmented control if necessary
     *
     *
     */
    constructor(options) {

        this._internalVars = {};

        //options with defaults set
        this._defaults = {

        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            initSegmentedControl.call(this);
            setEvents.call(this);
            setValue.call(this);
            initMask.call(this);
        }
    }

    //Public Methods

    /**
     * returns the time entered in a 24hr format
     * @return {{time: (string)}}
     */
    getTime() {
        this._internalVars.publicMethodUsed = true;
        getValue.call(this);
        return this._internalVars.timeInputFieldValue;
    }

    /**
     * sets the time value
     * @param {string} time String containing the 24hr time format
     */
    setTime(time) {
        this._internalVars.publicMethodUsed = true;
        getCurrentValue.call(this);
        setValue.call(this, time);
        getValue.call(this);
    }

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    this._internalVars.timeInputField.addEventListener('focus', getCurrentValue.bind(this));
    this._internalVars.timeInputField.addEventListener('keyup', checkKey.bind(this));
    this._internalVars.timeInputField.addEventListener('blur', getValue.bind(this));

    const eventName = UserAgentService._clickEventName();

    Array.prototype.forEach.call(this._internalVars.dropZoneObject, (element)=>{
        element.addEventListener(eventName, setSegmentValue.bind(this));
        interactjs(element)
            .dropzone({
                ondrop: segmentChange.bind(this)
            });
    });
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    this._internalVars.timeInputField.removeEventListener('focus', getCurrentValue.bind(this));
    this._internalVars.timeInputField.removeEventListener('keyup', checkKey.bind(this));
    this._internalVars.timeInputField.removeEventListener('blur', getValue.bind(this));

    const eventName = UserAgentService._clickEventName();

    Array.prototype.forEach.call(this._internalVars.dropZoneObject, (element)=>{
        element.removeEventListener(eventName, setSegmentValue.bind(this));
    });
}

/**
 * initMask()
 * instantiates the input field mask
 */
function initMask() {
    $(this._internalVars.timeInputField).mask("99:99",{});
}

/**
 * initSegmentedControl()
 * instantiates the segmented control component
 */
function initSegmentedControl() {
    this[this._options.segmentedControlVar] = new GDK.SegmentedControl({
        "content": this._internalVars.segmentedControlObject
    });
}

/**
 * setValue()
 * populates the input field value and calls a function to adjust the segmented control
 */
function setValue(time) {
    let entry;

    if(time) {
        entry = time;
    } else if(this._options.setInitialTime) {
        entry = this._options.setInitialTime;
    } else {
        entry = ':';
    }

    if(entry.indexOf(":") > 0) {
        let minutes = entry.substring(entry.indexOf(":")+1),
            hour = entry.substring(0,entry.indexOf(":"));

        if(minutes.length == 2 && hour.length < 3 && hour.length > 0) {
            if(hour < 24 && minutes < 60) {
                let numHour = parseInt(hour),
                    userEntry = false,
                    segmentVars = setChangeSegmentVars(numHour, userEntry);

                updatePeriod.call(this, segmentVars.period, segmentVars.changeSegment);

                let convertedTime = convertTo12hrFormat.call(this, numHour, minutes, segmentVars.changeSegment);

                if(entry === time) {
                    $(this._internalVars.timeInputField).mask("",{});
                    this._internalVars.timeInputField.setAttribute('value', convertedTime);
                    this._internalVars.timeInputField.value = convertedTime;
                    $(this._internalVars.timeInputField).mask("99:99",{});
                } else {
                    this._internalVars.timeInputField.setAttribute('value', convertedTime);
                }
            }
        }
    }
}

/**
 * setChangeSegmentVars(numHour, userEntry)
 * @param numHour
 * @param userEntry
 * @returns {{period: string, changeSegment: boolean}}
 * returns an object storing the time period and boolean indicating if the segmented control should be changed
 */
function setChangeSegmentVars(numHour, userEntry) {
    if (numHour > 12 && numHour < 24) {
        return {
            "period": "PM",
            "changeSegment": true
        };
    } else if (numHour < 1) {
        return {
            "period": "AM",
            "changeSegment": true
        };
    } else {
        if(userEntry === false) {
            return {
                "period": "AM",
                "changeSegment": true
            };
        } else {
            return {
                "period": "AM",
                "changeSegment": false
            };
        }
    }
}

/**
 * convertTo12hrFormat(numHour, minutes)
 * @param numHour
 * @param minutes
 * @returns {string}
 * returns a string converting a 24hr time format to a 12hr format
 */
function convertTo12hrFormat(numHour, minutes) {
    if(numHour > 12) {
        numHour = numHour - 12;
    } else if(numHour < 1) {
        numHour = numHour + 12;
    }

    let newHour = numHour.toString();

    if (newHour.length < 2) {
        newHour = "0" + newHour;
    }

    return newHour + minutes;
}

/**
 * convertTo24hrFormat(activeSegment, hour, minutes)
 * @param activeSegment
 * @param hour
 * @param minutes
 * @returns {string}
 * returns a string converting a 12hr time format to a 24hr format
 */
function convertTo24hrFormat(activeSegment, hour, minutes) {
    let newHour = parseInt(hour);
    if(activeSegment === 'PM') {
        hour = newHour + 12;
        if(hour === 24) {
            hour = hour - 12;
        }
    } else {
        if(newHour === 12) {
            hour = newHour - 12 + "0";
        }
    }

    return hour + ":" + minutes;
}

/**
 * parseInputValue()
 * Takes the time value and returns hours, minutes and the current segment
 * @returns {{hours: string, minutes: string, segment: string}}
 */
function parseInputValue() {
    this._internalVars.timeInputSegmentSelection = this._internalVars.timeInputObject.querySelector('.' + this._internalVars.activeClass);
    let activeElementText = this._internalVars.timeInputSegmentSelection.innerHTML,
        minutes = this._internalVars.timeInputField.value.slice(-2),
        hours = this._internalVars.timeInputField.value.substring(0,2);

    return {
        "hours" : hours,
        "minutes" : minutes,
        "segment" : activeElementText
    };
}

/**
 * getCurrentValue()
 * Set a local variable and stores the current time
 */
function getCurrentValue() {
    let timeEntry = parseInputValue.call(this);

    if (timeEntry.hours.length !== 0 && timeEntry.minutes.length !== 0) {
        this._internalVars.timeInputFieldCurrentValue = convertTo24hrFormat.call(this, timeEntry.segment, timeEntry.hours, timeEntry.minutes);
    } else {
        this._internalVars.timeInputFieldCurrentValue = '__:__';
    }

    this._internalVars.timeInputCompare = true;
}

/**
 * setSegmentValue()
 * Sets a new value for the segmented control when the user changes the segment manually
 */
function setSegmentValue() {
    let timeEntry = parseInputValue.call(this);

    if(timeEntry.hours.length !== 0 && timeEntry.minutes.length !== 0) {
        if(timeEntry.segment === "AM") {
            timeEntry.segment = "PM";
        } else {
            timeEntry.segment = "AM";
        }

        this._internalVars.timeInputFieldCurrentValue = convertTo24hrFormat.call(this, timeEntry.segment, timeEntry.hours, timeEntry.minutes);
        this._internalVars.timeInputCompare = true;
        getValue.call(this);
    }
}

/**
 * checkKey(event)
 * @param event
 * check if numbers or backspace was pressed
 */
function checkKey(event) {
    //Check the key pressed to trigger function (keys 0-9 and 'backspace')

    if ( (event.which > 47 && event.which < 58) || (event.which === 8) || (event.which > 95 && event.which < 106) || event.which == 229) {
        setTimePeriodVar.call(this);
    }
}

/**
 * setTimePeriodVar()
 * set an AM or PM variable and evaluate if the segment should change
 */
function setTimePeriodVar() {
    let hour,
        userEntry = true;

    //Desktops/iOS recognize the input mask as a value when checking the length, Android does not
    if (this._internalVars.timeInputField.value.length > 3) {
        hour = this._internalVars.timeInputField.value.substring(2, 0);
    }

    if (this._internalVars.timeInputField.value.length === 3) {
        hour = this._internalVars.timeInputField.value.substring(1, 0);
    }

    let segmentVars = setChangeSegmentVars(hour, userEntry);

    updatePeriod.call(this, segmentVars.period, segmentVars.changeSegment);
}

/**
 * updatePeriod(period, changeSegment)
 * @param period
 * @param changeSegment
 * changes the segmented control to AM or PM
 */
function updatePeriod(period, changeSegment) {
    let activeIndex;

    if (period === 'AM') {
        activeIndex = 0;
    } else {
        activeIndex = 1;
    }

    this._internalVars.timeInputSegmentSelection = this._internalVars.timeInputObject.querySelector('.' + this._internalVars.activeClass);

    if (period !== this._internalVars.timeInputSegmentSelection.innerHTML && changeSegment === true) {
        let delay = setTimeout(setNewSegmentValue.bind(this), 350);

        if(this._internalVars.timeInputSegmentSelection) {
            this._internalVars.timeInputSegmentSelection.classList.remove(this._internalVars.activeClass);
            this._internalVars.timeInputSegmentSelection.setAttribute('aria-checked', 'false');
        }

        this._internalVars.dropZoneObject[activeIndex].classList.add(this._internalVars.activeClass);
        this._internalVars.dropZoneObject[activeIndex].setAttribute('aria-checked', 'true');
        this._internalVars.draggableObject.classList.add(this._internalVars.animateClass);
        this._internalVars.draggableObject.classList.add(this._internalVars.activeDragClass);
        this._internalVars.draggableObject.innerText = '';
        this._internalVars.draggableObject.style.zIndex = 1;

        this._internalVars.timeInputSegmentSelection = this._internalVars.timeInputObject.querySelector('.' + this._internalVars.activeClass);
        let activeElementLeft = this._internalVars.timeInputSegmentSelection.offsetLeft;

        this._internalVars.draggableObject.style.left = activeElementLeft + 'px';
        this._internalVars.draggableObject.setAttribute('data-x', activeElementLeft);
        this._internalVars.timeInputSegmentChangeOnly = true;
    }
}

/**
 * setNewSegmentValue()
 * sets values of element after animation
 */
function setNewSegmentValue() {
    this._internalVars.timeInputSegmentSelection = this._internalVars.timeInputObject.querySelector('.' + this._internalVars.activeClass);
    let activeElementContent = this._internalVars.timeInputSegmentSelection.innerHTML;

    this._internalVars.draggableObject.innerHTML = activeElementContent;
    this._internalVars.draggableObject.style.zIndex = 2;
    this._internalVars.draggableObject.classList.remove(this._internalVars.animateClass);
    this._internalVars.draggableObject.classList.remove(this._internalVars.activeDragClass);
}

/**
 * segmentChange(event)
 * @param event
 * updates the segment visually when the user drops the segment selector
 */
function segmentChange(event) {
    let period = event.target.innerHTML,
        changeSegment = true,
        timeEntry = parseInputValue.call(this);

    updatePeriod.call(this, period, changeSegment);

    if(timeEntry.hours.length !== 0 && timeEntry.minutes.length !== 0){
        getValue.call(this);
    }
}

/**
 * getValue()
 * gets the value of the input field and segmented control selection
 */
function getValue() {
    let timeEntry = parseInputValue.call(this);

    if(timeEntry.hours < 24 && timeEntry.minutes < 60) {
        if(timeEntry.hours < 13) {
            this._internalVars.timeInputFieldValue = convertTo24hrFormat.call(this, timeEntry.segment, timeEntry.hours, timeEntry.minutes);
        } else {
            this._internalVars.timeInputFieldValue = timeEntry.hours + ":" + timeEntry.minutes;
        }
    }
    else if(timeEntry.hours >= 24 || timeEntry.minutes >= 60){
        this._internalVars.timeInputFieldValue = timeEntry.hours + ":" + timeEntry.minutes;
    }
    else {
        this._internalVars.timeInputFieldValue = "";
    }

    if(this._options.callBackOnTimeChange && this._internalVars.publicMethodUsed === false) {
        if ( (this._internalVars.timeInputFieldCurrentValue === '__:__') || (this._internalVars.timeInputFieldValue !== this._internalVars.timeInputFieldCurrentValue) && this._internalVars.timeInputCompare === true) {
            this._options.callBackOnTimeChange(this._internalVars.timeInputFieldValue);
            this._internalVars.timeInputCompare = false;
        } else if (this._internalVars.timeInputSegmentChangeOnly === true) {
            this._options.callBackOnTimeChange(this._internalVars.timeInputFieldValue);
            this._internalVars.timeInputSegmentChangeOnly = false;
        }
    }
    this._internalVars.publicMethodUsed = false;
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.timeInputObject = this._internalVars.node;

    this._internalVars.timeInputFieldClass = 'time-input';
    this._internalVars.segmentedControlClass = 'segmented-control-component';
    this._internalVars.activeClass = 'active';
    this._internalVars.dropZoneClass = '.dropzone';
    this._internalVars.draggableClass = '.sc-draggable';
    this._internalVars.activeDragClass = 'active-drag';
    this._internalVars.animateClass = 'animate-drop';

    this._internalVars.segmentedControlObject = this._internalVars.timeInputObject.querySelector('.' + this._internalVars.segmentedControlClass);
    this._internalVars.timeInputField = this._internalVars.timeInputObject.querySelector('.' + this._internalVars.timeInputFieldClass);
    this._internalVars.dropZoneObject = this._internalVars.timeInputObject.querySelectorAll(this._internalVars.dropZoneClass);
    this._internalVars.draggableObject = this._internalVars.timeInputObject.querySelector(this._internalVars.draggableClass);
    this._internalVars.timeInputFieldValue = this._internalVars.timeInputField.value;

    this._internalVars.timeInputFieldCurrentValue = null;
    this._internalVars.timeInputSegmentSelection = null;
    this._internalVars.timeInputCompare = false;
    this._internalVars.timeInputSegmentChangeOnly = null;
    this._internalVars.publicMethodUsed = false;
}

export default TimeInput;