//Require static assets

//Module Imports
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK CardSelections : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "initialActiveCard",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["number"],
        errorMessage            :   ["GDK CardSelections : initialActiveCard must be set to a number"]
    },
    {
        setting                 :   "cardSelectionSet",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK CardSelections : cardSelectionClick must be a function"]
    }
];

class CardSelections{

    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     *  A reference to the html More Background Pattern node
     *
     *  @param {number} [initialActiveCard=1]
     *  The number of the item to set as the active card on initialization. Must be greater than 0 and equal to or less than the number of cards.
     *
     *  @param {function} [cardSelectionSet]
     *   A callback function that is triggered when any card is set to active.
     */
    constructor(options) {

        this._internalVars = {
            node: null//used for content item
        };

        //options with defaults set
        this._defaults = {
            initialActiveCard: 1
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            init.call(this);

            this._internalVars.cardSelectionSection.setAttribute('role', 'radiogroup');

            Array.prototype.forEach.call(this._internalVars.cardSelectionsCards, (element)=> {
                if(!element.getAttribute('tabindex'))
                    element.setAttribute('tabindex', '0');

                if(!element.getElementsByTagName('input')[0].getAttribute('tabindex'))
                    element.getElementsByTagName('input')[0].setAttribute('tabindex', '-1');

                element.setAttribute('role', 'radio');
            });
        }
    }

    //Public Methods

    /**
     * Returns the active selected card
     * @return {Element}
     */
    currentActiveCard() {
        return this._internalVars.selectedCard;
    }

    /**
     * sets the initial active card using an index parameter
     * @param {Number} index Number indicating what the active card should be set to
     */
    setActiveCard(index){
        setInitialActiveCard.call(this, index);
    }

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    let eventName = "click";

    Array.prototype.forEach.call(this._internalVars.cardSelectionsCards, (element)=> {
        element.addEventListener(eventName, this._internalVars.handler);
        element.addEventListener('keyup', this._internalVars.handler);
    });
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    let eventName = "click";

    Array.prototype.forEach.call(this._internalVars.cardSelectionsCards, (element)=> {
        element.removeEventListener(eventName, this._internalVars.handler);
        element.removeEventListener('keyup', this._internalVars.handler);
    });
}

/**
 * init()
 * Sets the initial values
 */
function init() {
    let index = null;

    if (this._options.initialActiveCard) {
        index = this._options.initialActiveCard;
    } else {
       index = this._defaults.initialActiveCard;
    }

    setInitialActiveCard.call(this, index);
}

/**
 * Removes the class and attributes identifying the card as selected
 */
function resetSelected() {
    let selected = this._internalVars.cardSelectionsObject.querySelectorAll('.' + this._internalVars.cardSelectedClass);

    if (selected) {
        Array.prototype.forEach.call(selected, (el) => {
            el.classList.remove(this._internalVars.cardSelectedClass);
            el.getElementsByTagName('input')[0].checked = false;
            el.setAttribute('aria-checked', 'false');
        });
    }
}

/**
 * Adds attributes identifying the card as selected
 */
function setActiveCardAttributes() {
    this._internalVars.selectedCard = this._internalVars.cardSelectionsObject.querySelector('.' + this._internalVars.cardSelectedClass);

    this._internalVars.selectedCard.getElementsByTagName('input')[0].checked = true;
    this._internalVars.selectedCard.setAttribute('aria-checked', 'true');

    if (this._options.cardSelectionSet) {
        this._options.cardSelectionSet(this._internalVars.selectedCard);
    }
}

/**
 * setInitialActiveCard()
 * Sets the initial active card
 */
function setInitialActiveCard(index){
    resetSelected.call(this);

    if (index <= this._internalVars.cardSelectionsCards.length && index > 0) {
        this._internalVars.cardSelectionsCards[index-1].classList.add(this._internalVars.cardSelectedClass);
    } else {
        this._internalVars.cardSelectionsCards[0].classList.add(this._internalVars.cardSelectedClass);
    }

    setActiveCardAttributes.call(this);
}

/**
 * setActiveCard()
 * Sets the active card
 */
function setActiveCard(event){
    if(event.type == 'keypress' || event.type == 'keyup' && ((event.keyCode || event.which) != 13)){} else {
        resetSelected.call(this);

        event.currentTarget.classList.add(this._internalVars.cardSelectedClass);

        setActiveCardAttributes.call(this);
    }
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.cardSelectionsObject = this._internalVars.node;

    this._internalVars.cardSelectionSectionClass = 'card-selections';
    this._internalVars.cardSelectedClass = 'card-selections-selected';
    this._internalVars.cardSelectionsCardClass = 'card-selections-card';
    this._internalVars.selectedCard = null;

    this._internalVars.cardSelectionSection = this._internalVars.cardSelectionsObject.querySelector('.' + this._internalVars.cardSelectionSectionClass);
    this._internalVars.cardSelectionsCards = this._internalVars.cardSelectionsObject.querySelectorAll('.' + this._internalVars.cardSelectionsCardClass);

    this._internalVars.handler = setActiveCard.bind(this);
}

export default CardSelections;