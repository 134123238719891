//Require static assets

//Module Imports
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Make a Payment : Content must be defined and set to a DOM selector or Node"]
    }
];

/**
 * MakePayment Class
 */
class MakePayment{
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the div form field containing the make payment input node
     *
     */

    constructor(options) {

        this._internalVars = {};

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    Array.prototype.forEach.call(this._internalVars.radios, (radio)=> {
        radio.addEventListener('change', paymentTypeFieldsDisplay.bind(this));
    });

    Array.prototype.forEach.call(this._internalVars.paymentAmountRadios, (radio)=> {
        radio.addEventListener('change', enableDisableOtherAmount.bind(this));
    });

    this._internalVars.creditCardcarddholderSelect.addEventListener('change', mailingAddressDisplay.bind(this));
    this._internalVars.checkAccountholderSelect.addEventListener('change', mailingAddressDisplay.bind(this));

    this._internalVars.creditCardcarddholderSelect.addEventListener('change', prefillName.bind(this));
    this._internalVars.checkAccountholderSelect.addEventListener('change', prefillName.bind(this));

    Array.prototype.forEach.call(this._internalVars.newAddressRadios, (radio)=> {
        radio.addEventListener('change', newAddressFieldsDisplay.bind(this));
    });

    if(this._internalVars.nameOnCheckInput) {
        this._internalVars.nameOnCheckInput.addEventListener('blur', parseFirstName.bind(this));
    }
    if(this._internalVars.nameOnCardInput) {
        this._internalVars.nameOnCardInput.addEventListener('blur', parseFirstName.bind(this));
    }

    this._internalVars.saveMethodRadio.addEventListener('change', saveMethodFieldDisplay.bind(this));
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    Array.prototype.forEach.call(this._internalVars.radios, (radio)=> {
        radio.removeEventListener('change', paymentTypeFieldsDisplay.bind(this));
    });

    Array.prototype.forEach.call(this._internalVars.paymentAmountRadios, (radio)=> {
        radio.removeEventListener('change', enableDisableOtherAmount.bind(this));
    });

    this._internalVars.creditCardcarddholderSelect.removeEventListener('change', mailingAddressDisplay.bind(this));
    this._internalVars.checkAccountholderSelect.removeEventListener('change', mailingAddressDisplay.bind(this));

    Array.prototype.forEach.call(this._internalVars.newAddressRadios, (radio)=> {
        radio.removeEventListener('change', newAddressFieldsDisplay.bind(this));
    });

    if(this._internalVars.nameOnCheckInput) {
        this._internalVars.nameOnCheckInput.removeEventListener('blur', parseFirstName.bind(this));
    }
    if(this._internalVars.nameOnCardInput) {
        this._internalVars.nameOnCardInput.removeEventListener('blur', parseFirstName.bind(this));
    }

    this._internalVars.saveMethodRadio.removeEventListener('change', saveMethodFieldDisplay.bind(this));
}

function enableDisableOtherAmount(event) {
    if (event.target.value === 'other') {
        this._internalVars.currencyInput.removeAttribute('disabled');
    } else if (event.target.value !== 'other') {
        this._internalVars.currencyInput.setAttribute('disabled', 'disabled');
    }
}

function paymentTypeFieldsDisplay(event) {
    //Set variable values for which fields should be displayed
    let creditCardFieldsDisplay = false,
        checkFieldsDisplay = false,
        generalFieldsDisplay = false,
        saveMethodFieldsDisplay = false;

    this._internalVars.creditCardSelected = false;
    this._internalVars.checkSelected = false;

    if(event.target.value === 'card') {
        generalFieldsDisplay = true;
        creditCardFieldsDisplay = true;
        checkFieldsDisplay = false;
        this._internalVars.creditCardSelected = true;
        this._internalVars.addressFieldLabel.innerHTML = "Cardholder's";
    }

    if(event.target.value === 'check') {
        generalFieldsDisplay = true;
        checkFieldsDisplay = true;
        creditCardFieldsDisplay = false;
        this._internalVars.checkSelected = true;
        this._internalVars.addressFieldLabel.innerHTML = "Account holder's";
    }

    //Reset values if payment method is changed
    this._internalVars.creditCardcarddholderSelect.options[0].selected = true;
    this._internalVars.checkAccountholderSelect.options[0].selected = true;

    Array.prototype.forEach.call(this._internalVars.newAddressRadios, (radio)=> {
        radio.checked = false;
    });

    this._internalVars.saveMethodRadio.checked = false;

    toggleFieldDisplay.call(this, creditCardFieldsDisplay, checkFieldsDisplay, generalFieldsDisplay, saveMethodFieldsDisplay);
}

function toggleFieldDisplay(creditCardFieldsDisplay, checkFieldsDisplay, generalFieldsDisplay, saveMethodFieldsDisplay) {
    if($(this._internalVars.dynamicAddressField).is(':visible')) {
        $(this._internalVars.dynamicAddressField).slideUp();
    }
    if($(this._internalVars.dynamicNewAddressFields).is(':visible')) {
        $(this._internalVars.dynamicNewAddressFields).slideUp();
    }
    if(generalFieldsDisplay === true) {
        if($(this._internalVars.dynamicFieldGeneral).is(':hidden')) {
            $(this._internalVars.dynamicFieldGeneral).slideDown();
        }
    } else {
        if($(this._internalVars.dynamicFieldGeneral).is(':visible')) {
            $(this._internalVars.dynamicFieldGeneral).slideUp();
        }
    }
    if(creditCardFieldsDisplay === true) {
        if($(this._internalVars.dynamicCardFields).is(':hidden')) {
            $(this._internalVars.dynamicCardFields).slideDown();
        }
    } else {
        if($(this._internalVars.dynamicCardFields).is(':visible')) {
            $(this._internalVars.dynamicCardFields).slideUp();
        }
    }
    if(checkFieldsDisplay === true) {
        if($(this._internalVars.dynamicCheckFields).is(':hidden')) {
            $(this._internalVars.dynamicCheckFields).slideDown();
        }
    } else {
        if($(this._internalVars.dynamicCheckFields).is(':visible')) {
            $(this._internalVars.dynamicCheckFields).slideUp();
        }
    }
    if(saveMethodFieldsDisplay === true) {
        if($(this._internalVars.dynamicFieldSaveFields).is(':hidden')) {
            $(this._internalVars.dynamicFieldSaveFields).slideDown();
        }
    } else {
        if($(this._internalVars.dynamicFieldSaveFields).is(':visible')) {
            $(this._internalVars.dynamicFieldSaveFields).slideUp();
        }
    }
}

function mailingAddressDisplay(event) {
    let mailingAddressFields = false;
    if(event.target.value === 'Other Payee') {
        mailingAddressFields = true;
    }
    toggleMailingAddressFieldDisplay.call(this, mailingAddressFields);
}

function prefillName(event) {
    let addressLabel;
    if (this._internalVars.checkSelected === true) {
        addressLabel = "Account holder's";
    } else if (this._internalVars.creditCardSelected === true) {
        addressLabel = "Cardholder's";
    }
    if(event.target.value !== 'Other Payee' && event.target.value !== 'Please Select') {
        let value;
        for (var i = 0; i < event.target.length; i++) {
            if(event.target.options[i].value === event.target.value) {
                value = event.target.options[i].text;
            }
        }
        if (this._internalVars.creditCardSelected === true) {
            this._internalVars.nameOnCardInput.value = value;
        } else if (this._internalVars.checkSelected === true) {
            this._internalVars.nameOnCheckInput.value = value;
        }
    } else {
        this._internalVars.nameOnCardInput.value = '';
        this._internalVars.nameOnCheckInput.value = '';
        this._internalVars.addressFieldLabel.innerHTML = addressLabel;
    }
}

function toggleMailingAddressFieldDisplay(mailingAddressFields) {
    if(mailingAddressFields === true) {
        if($(this._internalVars.dynamicAddressField).is(':hidden')) {
            $(this._internalVars.dynamicAddressField).slideDown();
        }
    } else {
        if($(this._internalVars.dynamicAddressField).is(':visible')) {
            $(this._internalVars.dynamicAddressField).slideUp();
        }
    }
}

function newAddressFieldsDisplay(event) {
    let newAddressFields = false;
    if(event.target.value === 'new') {
        newAddressFields = true;
    }
    toggleNewAddressFieldsDisplay.call(this, newAddressFields);
}

function toggleNewAddressFieldsDisplay(newAddressFields) {
    if(newAddressFields === true) {
        if($(this._internalVars.dynamicNewAddressFields).is(':hidden')) {
            $(this._internalVars.dynamicNewAddressFields).slideDown();
        }
    } else {
        if($(this._internalVars.dynamicNewAddressFields).is(':visible')) {
            $(this._internalVars.dynamicNewAddressFields).slideUp();
        }
    }
}

function parseFirstName(event) {
    let nameField,
        addressLabel;
    if (this._internalVars.checkSelected === true) {
        nameField = this._internalVars.nameOnCheckInput;
        addressLabel = "Account holder's";
    } else if (this._internalVars.creditCardSelected === true) {
        nameField = this._internalVars.nameOnCardInput;
        addressLabel = "Cardholder's";
    }
    if (nameField.value.length > 0) {
        let firstName = event.target.value.split(" ")[0];
        if (firstName.slice(-1) === 's' || firstName.slice(-1) === 'S') {
            this._internalVars.addressFieldLabel.innerHTML = firstName + "'";
        } else {
            this._internalVars.addressFieldLabel.innerHTML = firstName + "'s";
        }

    } else {
        this._internalVars.addressFieldLabel.innerHTML = addressLabel;
    }
}

function saveMethodFieldDisplay(event) {
    if(event.target.checked === true) {
        if($(this._internalVars.dynamicFieldSaveFields).is(':hidden')) {
            $(this._internalVars.dynamicFieldSaveFields).slideDown();
        }
    } else {
        if($(this._internalVars.dynamicFieldSaveFields).is(':visible')) {
            $(this._internalVars.dynamicFieldSaveFields).slideUp();
        }
    }
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.makePaymentFields = this._internalVars.node;

    this._internalVars.paymentAmountID = 'payment-amount';
    this._internalVars.paymentAmountFields = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.paymentAmountID);
    this._internalVars.paymentAmountRadios = this._internalVars.paymentAmountFields.querySelectorAll("input[type='radio']");

    this._internalVars.currencyInputClass = 'currency-input';
    this._internalVars.currencyInput = this._internalVars.makePaymentFields.querySelector('.' + this._internalVars.currencyInputClass);

    this._internalVars.paymentMethodID = 'payment-method-selection';
    this._internalVars.paymentMethodFields = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.paymentMethodID);
    this._internalVars.radios = this._internalVars.paymentMethodFields.querySelectorAll('input');

    this._internalVars.dynamicFieldCheckClass = 'dynamic-fields-check';
    this._internalVars.dynamicCheckFields = this._internalVars.makePaymentFields.querySelector('.' + this._internalVars.dynamicFieldCheckClass);

    this._internalVars.dynamicFieldCardClass = 'dynamic-fields-card';
    this._internalVars.dynamicCardFields = this._internalVars.makePaymentFields.querySelector('.' + this._internalVars.dynamicFieldCardClass);

    this._internalVars.dynamicFieldGeneralClass = 'dynamic-fields-general';
    this._internalVars.dynamicFieldGeneral = this._internalVars.makePaymentFields.querySelector('.' + this._internalVars.dynamicFieldGeneralClass);

    this._internalVars.creditCardCardholderID = 'cardholder-credit-card';
    this._internalVars.creditCardCardholderField = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.creditCardCardholderID);
    this._internalVars.creditCardcarddholderSelect = this._internalVars.creditCardCardholderField.querySelector('select');

    this._internalVars.checkAccountholderID = 'accountholder-check';
    this._internalVars.checkAccountholderField = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.checkAccountholderID);
    this._internalVars.checkAccountholderSelect = this._internalVars.checkAccountholderField.querySelector('select');

    this._internalVars.nameOnCheckID = 'name-on-check';
    if(this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.nameOnCheckID)) {
        this._internalVars.nameOnCheckFields = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.nameOnCheckID);
        this._internalVars.nameOnCheckInput = this._internalVars.nameOnCheckFields.querySelector('input');
    }

    this._internalVars.nameOnCardID = 'name-credit-card';
    if(this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.nameOnCardID)) {
        this._internalVars.nameOnCardFields = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.nameOnCardID);
        this._internalVars.nameOnCardInput = this._internalVars.nameOnCardFields.querySelector('input');
    }

    this._internalVars.dynamicFieldAddressClass = 'dynamic-fields-address';
    this._internalVars.dynamicAddressField = this._internalVars.makePaymentFields.querySelector('.' + this._internalVars.dynamicFieldAddressClass);

    this._internalVars.addressFieldNameLabelID = 'address-name';
    this._internalVars.addressFieldLabel = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.addressFieldNameLabelID);

    this._internalVars.newAddressID = 'address';
    this._internalVars.newAddressFields = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.newAddressID);
    this._internalVars.newAddressRadios = this._internalVars.newAddressFields.querySelectorAll('input');

    this._internalVars.dynamicFieldNewAddressClass = 'dynamic-fields-new-address';
    this._internalVars.dynamicNewAddressFields = this._internalVars.makePaymentFields.querySelector('.' + this._internalVars.dynamicFieldNewAddressClass);

    this._internalVars.saveMethodId = 'save-method';
    this._internalVars.saveMethodField = this._internalVars.makePaymentFields.querySelector('#' + this._internalVars.saveMethodId);
    this._internalVars.saveMethodRadio = this._internalVars.saveMethodField.querySelector('input');

    this._internalVars.dynamicFieldSaveClass = 'dynamic-fields-save-method';
    this._internalVars.dynamicFieldSaveFields = this._internalVars.makePaymentFields.querySelector('.' + this._internalVars.dynamicFieldSaveClass);
}

export default MakePayment;