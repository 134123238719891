//Require static assets

//Module Imports
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Accordion : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "initiallyOpenedElement",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Accordion : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "shouldCloseOthers",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Accordion : shouldCloseOthers must be set to a boolean"]
    },
/**
 * Design Kit DEVELOPERS USE ONLY:
 *
 * forceOpenSingleAccordionElement is to be used only when there is a single, forced opened element of the accordion
 * Case Study: Mobile Navigation when there is a single Tier 1 element, which is forcefully opened in the mobile view,
 * so it won't require additional click to open when user use the navigation.
 *
 * NOTE: The force-open element DO NOT have accordion functionality to retract and stays expanded at all times.
 */
    {
        setting                 :   "forceOpenSingleAccordionElement",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Accordion : forceOpenSingleAccordionElement must be set as a boolean"]
    },
    {
        setting                 : "accordionOpenClicked",
        isRequired              : false,
        validate                : "type",
        possibleValues          : ["function"],
        errorMessage            : ["GDK Accordion : accordionOpenClicked must be a function"]
    },
    {
        setting                 : "accordionCloseClicked",
        isRequired              : false,
        validate                : "type",
        possibleValues          : ["function"],
        errorMessage            : ["GDK Accordion : accordionCloseClicked must be a function"]
    }
];

class Accordion{

    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the html accordion node
     *
     * @param {string|Object} initiallyOpenedElement
     * A reference to the html accordion li tag element that should be opened on start
     *
     * @param {boolean} [shouldCloseOthers="true"]
     * Used to set URL target to open in same page or in a new window/tab
     *
     * @param {function} [accordionOpenClicked]
     * A callback function that gets fired when an accordion tab opens up. Param currentNode is optional and refers to the target clicked.
     *
     * @param {function} [accordionCloseClicked]
     *  A callback function that gets fired when an accordion tab closes. Param currentNode is optional and refers to the target clicked.
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for content item
            headline:null,
            contentContainer:null,
            content:null
        };

        //options with defaults set
        this._defaults = {
            shouldCloseOthers : true,
            forceOpenSingleAccordionElement : false
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            if (this._options.initiallyOpenedElement) {
                openInitialElements.call(this);
            }
            let allAccordionHeadlines = this._internalVars.node.querySelectorAll('.accordion-headline');
            Array.prototype.forEach.call(allAccordionHeadlines, function(el, i){
                if(!el.getAttribute('tabindex'))
                    el.setAttribute('tabindex', '0');

                el.setAttribute('role', 'button');
            });
        }

        window.addEventListener('resize', () => {
            //handleResize.call(this);
        });
    }

    //Public Methods

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * openInitialElement()
 * a private method which is only invoked when the initiallyOpenedElement property is set.
 */
function openInitialElements() {
    let element;
    if(typeof this._options.initiallyOpenedElement === 'string') {
        element = this._internalVars.node.querySelector(this._options.initiallyOpenedElement);
        openAccordionElement.call(this, element);
    } else if(typeof this._options.initiallyOpenedElement === 'object') {
        element = this._options.initiallyOpenedElement;
        openAccordionElement.call(this, element);
    }
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {

    if (this._internalVars.node.childElementCount == 1 && this._options.forceOpenSingleAccordionElement) {
        console.log("The single element will be forced opened");
        forceOpenAccordion(this._internalVars.node.children[0]);
    } else {
        const eventName = UserAgentService._clickEventName();

        Array.prototype.forEach.call(this._internalVars.headline, (el, i)=>{
            el.addEventListener(eventName,this._internalVars.handler);
            el.addEventListener("keyup", this._internalVars.handler);
            el.addEventListener('keyup', function(e){
                if(e.shiftKey && e.keyCode == 9 || e.keyCode == 9){
                    el.classList.add('keyboard-focus');
                }

            });
            el.addEventListener('blur', function(e){
                    el.classList.remove('keyboard-focus');


            });
        });
    }
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();

    Array.prototype.forEach.call(this._internalVars.headline, (el, i)=>{
        el.removeEventListener(eventName,this._internalVars.handler);
    });
}

/**
 * toggleAccordion()
 * opens or closes an accordions content
 *
 * @param  {Object} node    DOM node that was clicked
 */
function toggleAccordion(el) {
    if(el.type == 'keypress' || el.type == 'keyup' && ((el.keyCode || el.which) != 13)){
        return;
    }

    let currentNode = el.currentTarget;
    let parentNode = currentNode.parentNode;
    let contentContainer = parentNode.querySelector(".accordion-content-container");
    let content = contentContainer.querySelector(".accordion-content");

    if(!contentContainer.parentNode.classList.contains('open')){
        if (this._options.accordionOpenClicked)
        if(this._options.accordionOpenClicked(currentNode)===false) return false;
        if(this._options.shouldCloseOthers){
            closeAccordions.call(this);
        }

        parentNode.classList.add("open");
        $(contentContainer).slideDown();
        currentNode.setAttribute('aria-expanded', 'true');
        contentContainer.setAttribute('aria-hidden', 'false');
    } else {
        if (this._options.accordionCloseClicked)
        if(this._options.accordionCloseClicked(currentNode)===false) return false;
        parentNode.classList.remove("open");
        $(contentContainer).slideUp();
        currentNode.setAttribute('aria-expanded', 'false');
        contentContainer.setAttribute('aria-hidden', 'true');
    }

}

/**
 * openAccordionElement(element)
 * @param element
 * @returns {boolean}
 *
 * private method to simply open the element that have been passed as parameter (no events)
 */
function openAccordionElement(element) {
    let contentContainer = element.querySelector(".accordion-content-container");
    let nodeHeadline = element.querySelector('.accordion-headline');
    let content = contentContainer.querySelector(".accordion-content");

    if(!contentContainer.parentNode.classList.contains('open')){
        if (this._options.accordionOpenClicked)
            if(this._options.accordionOpenClicked(nodeHeadline)===false) return false;
        if(this._options.shouldCloseOthers){
            closeAccordions.call(this);
        }

        $(contentContainer).slideDown();
        element.classList.add("open");
        nodeHeadline.setAttribute('aria-expanded', 'true');
        contentContainer.setAttribute('aria-hidden', 'false');
    } else {
        if (this._options.accordionCloseClicked)
            if(this._options.accordionCloseClicked(nodeHeadline)===false) return false;
        element.classList.remove("open");
        $(contentContainer).slideUp();
        nodeHeadline.setAttribute('aria-expanded', 'false');
        contentContainer.setAttribute('aria-hidden', 'true');
    }
}

function forceOpenAccordion(el) {
    el.classList.add('force-open');
}

/**
 * closeAccordions()
 * closes all open accordains
 */
function closeAccordions() {

    let openAccordians = this._internalVars.node.parentNode.querySelectorAll(".accordion > li.open");
    Array.prototype.forEach.call(openAccordians, (el, i)=>{
        el.classList.remove("open");
        let contentContainer = el.querySelector(".accordion-content-container");
        el.querySelector('.accordion-headline').setAttribute('aria-expanded', 'false');
        contentContainer.setAttribute('aria-hidden', 'true');
        $(contentContainer).slideUp();
    });

}

/**
 * setLocalVars()
 * recalulates the max height when you resize the browser
 */
function handleResize() {
    if(this._internalVars.node.parentNode){
    let openAccordions = this._internalVars.node.parentNode.querySelectorAll(".accordion > li.open");
    Array.prototype.forEach.call(openAccordions, (el, i)=>{

        let contentContainer = el.querySelector(".accordion-content-container");
        let content = contentContainer.querySelector(".accordion-content");



    });}
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.headline = this._internalVars.node.querySelectorAll(".accordion-headline");
    let allLIAccordions = this._internalVars.node.parentNode.querySelectorAll(".accordion > li");

    Array.prototype.forEach.call(allLIAccordions, (el, i)=>{
        if(el.classList.contains('open')){
            el.querySelector('.accordion-headline').setAttribute('aria-expanded', 'true');
            el.querySelector('.accordion-content-container').setAttribute('aria-hidden', 'false');
        }else{
            el.querySelector('.accordion-headline').setAttribute('aria-expanded', 'false');
            el.querySelector('.accordion-content-container').setAttribute('aria-hidden', 'true');
        }
    });

    this._internalVars.handler = toggleAccordion.bind(this);
}


export default Accordion;