//Require static assets

//Module Imports
import baseComponent from '../baseComponent';
import appState from '../appState';
import * as utils from '../utils';
import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';
datepickerFactory($);

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",//a type or a value
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK DatePicker : Content must be defined and set to a DOM selector or Node"]
    },
     {
        setting                 :   "monthYearDropdowns",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK DatePicker : monthYearDropdowns must be set to a boolean"]
    },
     {
        setting                 :   "dateFormatShort",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK DatePicker : dateFormatShort must be set to a boolean"]
    },
     {
        setting                 :   "minDate",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["number","date","string","object"],
        errorMessage            :   ["GDK DatePicker : minDate must be set to a number, date, or string"]
    },
     {
        setting                 :   "maxDate",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["number","date","string","object"],
        errorMessage            :   ["GDK DatePicker : maxDate must be set to a number, date, or string"]
    },
    {
        setting                 :   "showWeekends",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK DatePicker : showWeekends must be set to a boolean"]
    },
    {
        setting                 :   "dateSelected",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK DatePicker : dateSelected must be a callback function"]
    },
    {
        setting                 :   "disabled",
        isRequired              :   false,
        validate                :   "type",//a type or a value
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK DatePicker : disabled must be a boolean"]
    }
];

/**
 * DatePicker Class
 */
class DatePicker{

    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the html date node
     *
     * @param {boolean} [monthYearDropdowns=false]
     * Determines whether or not to show select boxes for the month and year
     *
     * @param {boolean} [dateFormatShort=false]
     * Determines whether to use the short format date instead of the default long format
     *
     * @param {function} [dateSelected]
     * Callback gets fired when a user selects a date
     *
     * @param {Date|number|string} [minDate=null]
     * The minimum selectable date. When set to null, there is no minimum.
     *
     * @param {Date|number|string} [maxDate=null]
     * The maximum selectable date. When set to null, there is no maximum.
     *
     * @param {boolean} [hideWeekends=false]
     * Set to true if you would like to disable the weekends in the calender.
     *
     * @param {boolean} [disabled]
     * Set to true if you would like to disable the input and calendar button.
     *
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for content item
            button: null,
            wrapper : null,
            wrapperElementId : "wrapper",
            marginLeft : 15,
            marginTopBottomLeft : 10,
            marginTopBottomRight : 28,
            breakpoint : 768,
            calendarWidth: 300,
            borderWidth: 4
        };

        //options with defaults set
        this._defaults = {
            monthYearDropdowns: false,
            dateFormatShort: false,
            minDate: null,
            maxDate: null,
            hideWeekends : false
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            init.call(this);
            this._internalVars.button.setAttribute('tabindex', '-1');

        }
    }

    //Public Methods

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        $(this._internalVars.node).datepicker("destroy");
		this._internalVars.node.parentNode.removeChild(this._internalVars.node);

		//a little garbage collection
		for (var variableKey in this){
			if (this.hasOwnProperty(variableKey)){
				delete this[variableKey];
			}
		}
    }

    /**
     * Set Min Date for the DatePicker Instance
     * @param {number|Date|string|object} minDate The starting date for the date picker instance
     */
    setMinDate(minDate) {
        this._options.minDate = new Date(minDate);
    }

    /**
     * Set Max Date for the DatePicker Instance
     * @param {number|Date|string|object} maxDate The ending date for the date picker instance
     */
    setMaxDate(maxDate) {
        this._options.maxDate = new Date(maxDate);
    }

    /**
     * Set Min and Max Dates for the DatePicker Instance
     * @param {number|Date|string|object} minDate The starting date for the date picker instance
     * @param {number|Date|string|object} maxDate The ending date for the date picker instance
     */
    setMinMaxDateLimits(minDate, maxDate) {
        this._options.minDate = new Date(minDate);
        this._options.maxDate = new Date(maxDate);
    }

    /**
     * Disables a specific date picker
     * @param {String} datePickerId ID of the date picker to be disabled
     */
    disableDatePicker() {
        let option = 'disable';
        datePickerState.call(this, option);
    }

    /**
     * Enable a specif date picker
     * @param {Object} datePickerId ID of the date picker to be enabled
     */
    enableDatePicker(datePickerId) {
        let option = 'enable';
        datePickerState.call(this, option);
    }
}


/**
 * set all the local vars to passed in options
 */
function setLocalVars() {

    var _this = this;

    try{
        //determine the type of content passed in
        if(this._internalVars.contentType === 'string'){
            this._internalVars.node = document.querySelector(this._options.content);
        }else if(this._internalVars.contentType === 'domNode'){
            this._internalVars.node = this._options.content;
        }

        let dpOptions = {
        	showOtherMonths: false,
        	showOn: "button",
        	buttonText: "",
        	nextText: "",
        	prevText: "",
        	dayNamesMin: [ "S", "M", "T", "W", "T", "F", "S" ],
        	changeMonth: false,
        	changeYear: false,
            showOptions: { direction: "left" },
            beforeShow: updateDatepicker.bind(this),
            onClose: closedDatepicker.bind(this),
            onSelect: function(dateText, inst) {
                var date = $(this).val();

                _this._internalVars.node.parentNode.classList.remove("form-field--error");

                 // Get all spans and remove ones bearing class "form-message"
                var spans = _this._internalVars.node.parentNode.getElementsByTagName('span');
                for(var e = 0; spans.length > e; e++) {
                    if(-1 !== spans[e].className.indexOf("form-message")) {
                        spans[e].parentNode.removeChild(spans[e]);
                    }
                }

                if(_this._options.dateSelected){
                    _this._options.dateSelected(date);
                }
            }
        };

        if(this._options.hideWeekends){
            dpOptions.beforeShowDay = $.datepicker.noWeekends;
        }

        $( this._internalVars.node ).datepicker(dpOptions);

        this._internalVars.button = this._internalVars.node.nextElementSibling;
        this._internalVars.wrapper = document.querySelector("#" + this._internalVars.wrapperElementId);
        this._internalVars.handler = toggleHover.bind(this);
        this._internalVars.handlerOverride = calendarPositionOverride.bind(this);

        let $datapicker = $(".ui-datepicker");

        if($datapicker.data("initialized") !== true){
            $datapicker.data("initialized", true);
            document.addEventListener("animationstart", insertListener, false); // standard + firefox
            document.addEventListener("MSAnimationStart", insertListener, false); // IE
            document.addEventListener("webkitAnimationStart", insertListener, false); // Chrome + Safari
        }
    } catch (ex) {
        console.error("Error : ", ex.message);
    }
}

/**
 * Sets all the events needed for the component
 */
function setEvents() {
    this._internalVars.button.addEventListener("mouseover", this._internalVars.handler, false);
    this._internalVars.button.addEventListener("mouseout", this._internalVars.handler, false);
    window.addEventListener('resize', handleResize.bind(this));

    //special case if a datepicker exist in a modal - override third party jquery snippet
    if(utils.isChild('modal', this._internalVars.button)){
        this._internalVars.button.addEventListener("click", this._internalVars.handlerOverride);
    }
}

function init() {
    if (this._options.disabled === true) {
        let option = 'disable';

        datePickerState.call(this, option);
    }
}

/**
 * removes all events from the component
 */
function removeEvents(){
    this._internalVars.button.removeEventListener("mouseoout", this._internalVars.handler, false);
}

/**
 * Checks if the ID passed from the enable or disable public method exists
 * @param {string} option  Variable identifying enable or disable
 */
function datePickerState(option) {
    let datePickerId = this._internalVars.node.id,
        datePicker = document.getElementById(datePickerId),
        parent = datePicker.parentNode,
        button = parent.querySelector(".ui-datepicker-trigger"),
        hasButton = false;

    if(button) {
        hasButton = true;
    }

    if (option === 'disable') {
        disable.call(this, datePicker, button, hasButton);
    } else if (option === 'enable') {
        enable.call(this, datePicker, button, hasButton);
    }
}

/**
 * Disables the specified date picker
 * @param {object} datePicker  The date picker HTML element
 * @param {object} button  The date picker calendar button HTML element
 * @param {boolean} hasButton Boolean indicating if calendar button exists
 */
function disable(datePicker, button, hasButton)  {
    datePicker.setAttribute('disabled', 'disabled');
    if(hasButton === true) {
        button.setAttribute('disabled', 'disabled');
    }
}

/**
 * Enables the specified date picker
 * @param {object} datePicker  The date picker HTML element
 * @param {object} button  The date picker calendar button HTML element
 * @param {boolean} hasButton Boolean indicating if calendar button exists
 */
function enable(datePicker, button, hasButton) {
    datePicker.removeAttribute('disabled');
    if(hasButton === true) {
        button.removeAttribute('disabled');
    }
}

/**
 * toggles the active state of the neighboring input field
 */
function toggleHover(){
    if(this._internalVars.node.classList.contains("active"))
        this._internalVars.node.classList.remove("active");
    else
        this._internalVars.node.classList.add("active");
}

/**
 * listens for the select box to be inserted and wraps it in a container
 */
function insertListener(event){
	if (event.animationName == "nodeInserted") {
        if(!$(".ui-datepicker-year").parent().hasClass("select-box"))
            $(".ui-datepicker-year").wrap( "<div class=\"select-box\"></div>" );

        if(!$(".ui-datepicker-month").parent().hasClass("select-box"))
            $(".ui-datepicker-month").wrap( "<div class=\"select-box\"></div>" );
	}
}

/**
 * runs when the datepicker window closes
 */
function closedDatepicker() {
    //re-assign button variable since datepicker replaces the original node
    this._internalVars.button = this._internalVars.node.nextElementSibling;
    setEvents.call(this);
    this._internalVars.button.setAttribute('tabindex', '-1');
}

/**
 * runs before displaying the datapicker and updates it based on the current options
 */
function updateDatepicker(el,obj) {

    let changeOptions = {};

    changeOptions.minDate = this._options.minDate;
    changeOptions.maxDate = this._options.maxDate;

    if(this._options.monthYearDropdowns){
        //adds the month and year dropdowns
        changeOptions.changeMonth = true;
        changeOptions.changeYear = true;

        //hide the month arrows
        $(".ui-datepicker").addClass("date-picker-hide-arrows");

    } else {
        //removes the month and year dropdowns
        changeOptions.changeMonth = false;
        changeOptions.changeYear = false;

        //displays the month arrows
        $(".ui-datepicker").removeClass("date-picker-hide-arrows");
    }

    //set the date format to full or short
    if(this._options.dateFormatShort){
        changeOptions.dateFormat = "mm/yy";
    } else {
        changeOptions.dateFormat = "mm/dd/yy";
    }

    $(el).datepicker(
        "change",
        changeOptions
    );


    positionDatePicker.call(this, el);


    $.datepicker._shouldFocusInput = function(){return false;};

}

/**
 * Positions calendar
 * @param {object} el HTML element
 */
function positionDatePicker(el){
    //need to add code to check if position has changed before recalculating
    let winWidth = document.body.clientWidth;
    let winHeight = window.innerHeight || document.documentElement.clientHeight;
    let inputOffset = this._internalVars.node.getBoundingClientRect();
    let containerOffset = this._internalVars.wrapper.getBoundingClientRect();
    let containerOffsetLeft = containerOffset.left;
    let containerWidth = this._internalVars.wrapper.offsetWidth;
    let inputWidth = this._internalVars.node.offsetWidth + this._internalVars.node.nextElementSibling.offsetWidth;
    let isRight = false;

    if(winWidth >= this._internalVars.breakpoint){

        $.datepicker._pos = $.datepicker._findPos(el);
        //figure out correct horizontal direction
        if( $.datepicker._pos[0] + this._internalVars.calendarWidth + inputWidth < containerWidth){
            $.datepicker._pos[0] += (inputWidth + this._internalVars.marginLeft);
        }else{
            $.datepicker._pos[0] -= (this._internalVars.calendarWidth-inputWidth);
            isRight = true;
        }

        //figure out correct vertical direction
        if( inputOffset.top  + this._internalVars.calendarWidth < winHeight ){
            if(!isRight){
                $.datepicker._pos[1] -= this._internalVars.marginTopBottomLeft;
            } else {
                $.datepicker._pos[1] += ((inputOffset.bottom - inputOffset.top) - this._internalVars.borderWidth) + this._internalVars.marginTopBottomLeft;
            }
        }else{
            if(!isRight){
                $.datepicker._pos[1] -= (this._internalVars.calendarWidth - this._internalVars.marginTopBottomRight);
            } else {
                $.datepicker._pos[1] -= (this._internalVars.calendarWidth + this._internalVars.marginTopBottomRight);
            }
        }
    }
}

/**
 * handles window resize
 */
function handleResize(){
    $( this._internalVars.node ).datepicker( "hide" );
}

function calendarPositionOverride(){
    if(appState.mode == 'desktop'){
        document.querySelector('#ui-datepicker-div').style.top = document.querySelector('.modal .hasDatepicker').offsetTop + 'px';
    }
}


export default DatePicker;
