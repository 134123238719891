//Require static assets

//Module Imports
import baseComponent from '../baseComponent';

const validateSettings = [
  {
    setting                 :   "content",
    isRequired              :   true,
    validate                :   "type",//a type or a value
    possibleValues          :   ["string","object"],
    errorMessage            :   ["GDK InPageNavigation : Content must be defined and set to a DOM selector or Node"]
  },
  {
    setting                 :   "onClicked",
    isRequired              :   false,
    validate                :   "type",
    possibleValues          :   ["function"],
    errorMessage            :   ["GDK InPageNavigation : onClicked must be a function"]
  },
  {
    setting                 :   "startingPos",
    isRequired              :   false,
    validate                :   "type",//a type or a value
    possibleValues          :   ["number"],
    errorMessage            :   ["GDK InPageNavigation : startingPos must be set to a number"]
  },
  {
    setting                 :   "autoHide",
    isRequired              :   false,
    validate                :   "type",//a type or a value
    possibleValues          :   ["boolean"],
    errorMessage            :   ["GDK InPageNavigation : autoHide must be set to be boolean. Default value is False."]
  }
];

class InPageNavigation{

  /**
   * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
   * @param {string|Object} content
   * A reference to the html 'in page navigation' node
   *
   * @param {function} [onClicked]
   *  Callback function fired once a 'in-page-item' is clicked
   *
   * @param {number} [startingPos=1]
   * The number of the navigation item to display on initialization
   */

  constructor(options) {

    this._internalVars = {
      node: null,//used for content item
      navItem: null,
      marker: null
    };

    //options with defaults set
    this._defaults = {
      startingPos: 1,
      autoHide: false
    };

    // Create options by extending defaults with the passed in arguments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    //if the required options are valid set up the environment
    if( baseComponent.validateSettings(this._options, validateSettings) ){
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);
      setEvents.call(this);
      setStartPosition.call(this);
    }
  }

  //Public Methods


  /**
   * @param {number} [newIndex]
   * newIndex is the number of the navigation item to go to first on initialization
   */
  goTo(newIndex){

    let index = newIndex - 1;

    Array.prototype.map.call(this._internalVars.navItem, (e, rank)=> {
      if(rank===index){
        e.classList.add("in-page-item--selected");

        let topPosition = e.offsetTop;
        let nodeHeight = e.offsetHeight;

        // set marker to correct position
        this._internalVars.marker.style.top = topPosition + "px";
        this._internalVars.marker.style.height = nodeHeight + "px";
      } else {
        e.classList.remove("in-page-item--selected");
      }
    });
  }

  /**
   * destroy()
   * removes the node from the dom and any events attached
   */
  destroy(){
    removeEvents.call(this);
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (var variableKey in this){
      if (this.hasOwnProperty(variableKey)){
        delete this[variableKey];
      }
    }
  }

}

// Private Methods

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
  //determine the type of content passed in
  if(this._internalVars.contentType === 'string'){
    this._internalVars.node = document.querySelector(this._options.content);
  }else if(this._internalVars.contentType === 'domNode'){
    this._internalVars.node = this._options.content;
  }

  this._internalVars.node.insertAdjacentHTML('beforeend', `<li class="in-page-marker"></li>`);

  this._internalVars.navItem = this._internalVars.node.querySelectorAll(".in-page-item");
  this._internalVars.marker = this._internalVars.node.querySelector(".in-page-marker");

  this._internalVars.navId = this._internalVars.node.id;

  this._internalVars.backgroundItems = [];
  this._internalVars.backgroundItemsTop = [];
  this._internalVars.backgroundItemsBottom = [];

  this._internalVars.backgroundItemsDefined = false;

  this._internalVars.handler = inPageItemClickHandler.bind(this);
}


/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {

  let eventName = "click";

  //set click events
  Array.prototype.forEach.call(this._internalVars.navItem, (el, i)=>{
    el.addEventListener(eventName,this._internalVars.handler);
  });

  window.addEventListener('scroll', scrollPosition.bind(this));
  window.addEventListener('scroll', checkNode.bind(this));
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {

  let eventName = "click";

  //remove click events
  Array.prototype.forEach.call(this._internalVars.navItem, (el, i)=>{
    el.removeEventListener(eventName,this._internalVars.handler);
  });
}

/**
 * if In Page Navigation Node exists, run processes
 */
function checkNode() {
  if(document.getElementById(this._internalVars.navId)) {
    defineBackgroundItems.call(this);
  }
}

/**
 * Create array of all items with a bg or gradient background spanning the full width of the page
 */
function defineBackgroundItems() {
  if (this._internalVars.backgroundItemsDefined === false) {
    let gradientItems = document.querySelectorAll('.bg-gradient'),
        coloredItems = document.querySelectorAll('.bg-color'),
        crossSellItems = document.querySelectorAll('.cross-sell-image');

    Array.prototype.forEach.call(gradientItems, (el)=> {
      if (el.clientWidth === document.body.scrollWidth) {
        this._internalVars.backgroundItems.push(el);
      }
    });

    Array.prototype.forEach.call(coloredItems, (el)=> {
      if (el.clientWidth === document.body.scrollWidth) {
        this._internalVars.backgroundItems.push(el);
      }
    });

    Array.prototype.forEach.call(crossSellItems, (el)=> {
      if (el.clientWidth === document.body.scrollWidth) {
        this._internalVars.backgroundItems.push(el);
      }
    });

    this._internalVars.backgroundItemsDefined = true;
  }
  checkBackgroundItems.call(this);
}

function checkBackgroundItems() {
  this._internalVars.backgroundItemsTop = [];
  this._internalVars.backgroundItemsBottom = [];
  Array.prototype.forEach.call(this._internalVars.backgroundItems, (el)=> {
    let top = $(el).offset().top - window.pageYOffset,
        bottom = top + el.clientHeight;
    if(top > 0 && top < window.innerHeight ) {
      addBackgroundItemsToArray.call(this, el);
    } else if (bottom > 0 && bottom < window.innerHeight) {
      addBackgroundItemsToArray.call(this, el);
    }
  });
  changeNavItemColor.call(this);
}

function addBackgroundItemsToArray(el) {
  let crossSellHeight = el.clientHeight / 2;

  if(el.classList.contains('cross-sell-image')) {
    this._internalVars.backgroundItemsTop.push($(el).offset().top + crossSellHeight);
    this._internalVars.backgroundItemsBottom.push($(el).offset().top + el.clientHeight);
  } else {
    this._internalVars.backgroundItemsTop.push($(el).offset().top);
    this._internalVars.backgroundItemsBottom.push($(el).offset().top + el.clientHeight);
  }
}

function changeNavItemColor() {
  Array.prototype.forEach.call(this._internalVars.navItem, (el)=> {
    let top = $(el).offset().top - window.pageYOffset,
        height = el.clientHeight,
        changePoint = top + (height / 2),
        changeColor = true;

    Array.prototype.forEach.call(this._internalVars.backgroundItems, (ele, ind)=> {
      if(changeColor === true) {
        if (changePoint + window.pageYOffset > this._internalVars.backgroundItemsTop[ind] && changePoint + window.pageYOffset < this._internalVars.backgroundItemsBottom[ind]) {
          el.classList.add('changeFontColor');
          changeColor = false;
        } else {
          if (el.classList.contains('changeFontColor') ) {
            el.classList.remove('changeFontColor');
          }
        }
      }
    });
  });

  let  component = document.getElementById(this._internalVars.navId),
      navMarker =  component.querySelector('.in-page-marker'),
      selected = component.querySelector('.in-page-item--selected');

  if (selected.classList.contains('changeFontColor')) {
    navMarker.classList.add('changeFontColor');
  } else {
    if (navMarker.classList.contains('changeFontColor')) {
      navMarker.classList.remove('changeFontColor');
    }
  }
}

/**
 * inPageItemClickHandler()
 * Opens tab content for clicked tab and hides the rest
 */
function inPageItemClickHandler(el){
  el.preventDefault();
  let currentNode = el.currentTarget;

  // get position and height of currentNode
  let topPosition = currentNode.offsetTop;
  let nodeHeight = currentNode.offsetHeight;


  // add selected class
  Array.prototype.map.call(this._internalVars.navItem, (e, rank)=> {
    if(e===currentNode){
      e.classList.add("in-page-item--selected");
    } else {
      e.classList.remove("in-page-item--selected");
    }
  });

  // set marker to correct position
  this._internalVars.marker.style.top = topPosition + "px";
  this._internalVars.marker.style.height = nodeHeight + "px";

  let currentNodeSection = currentNode.getElementsByTagName("a");

  if (currentNodeSection[0].hasAttribute('data-link')) {
    let getDataLink = currentNodeSection[0].getAttribute("data-link");
    let section = document.getElementById(getDataLink);
    if (section) {
        //start will adjusting this number to a negative and go from there.  Will adjust position of a.
        let top = -40;
        let sectionTop = section.offsetTop + top;

        window.scrollTo(0, sectionTop);
    }

  }


  // fire onClicked Callback if there is one
  setTimeout( ()=> {
    if(this._options.onClicked)
      this._options.onClicked(currentNode);
  }, 10);

}

/**
 * setStartPosition()
 * Set starting link
 */
function setStartPosition() {

  let linkIndex = this._options.startingPos - 1;

  if(this._options.autoHide) {
    if(!this._internalVars.node.classList.contains('hidden')) {
      toggleComponent.call(this);
    }
  }

  Array.prototype.map.call(this._internalVars.navItem, (e, rank)=> {
    if(rank===linkIndex){
      e.classList.add("in-page-item--selected");

      let topPosition = e.offsetTop;
      let nodeHeight = e.offsetHeight;

      this._internalVars.marker.style.top = topPosition + "px";
      this._internalVars.marker.style.height = nodeHeight + "px";
    }
  });


}

/**
 * Toggle Component Function
 * Displays and hides the component
 */
function toggleComponent() {
  if(this._internalVars.node.classList.contains('hidden')){
    this._internalVars.node.classList.remove('hidden');
  }
  else {
    this._internalVars.node.classList.add('hidden');
  }
}

function scrollPosition(){
  var inPageNav = this._internalVars.node;
  if (inPageNav.parentNode.parentNode.getBoundingClientRect().top - 65 < 0) {
    inPageNav.style.position = "fixed";
    inPageNav.style.top = window.innerHeight/2 - inPageNav.offsetHeight + "px";
  } else {
    inPageNav.style.position = "absolute";
    inPageNav.style.top = "";
  }

}

export default InPageNavigation;

