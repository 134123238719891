//Module Imports
import * as utils from '../utils';

function initAllTextareaCountdown(){
    initAll(".textarea-countdown-container", "TextareaCountdown");
}

function initAllToolTips(){
    initAll(".tooltip-trigger", "Tooltip");
}

function initAllVML(){
    initAll(".view-more-less", "ViewMoreLess");
}

function initAllMoreInfoBtn(){
    initAll(".more-info-button-container", "MoreInfoButton");
}

function initAllTabs(){
    initAll(".tabs", "Tabs");
}

function initAllAlert(){
    initAll(".alert", "Alert");
}

function initAllSortableTable(){
    initAll(".table--sortable", "SortableTable");
}

function initAllAccordions(){
    initAll(".accordion", "Accordion");
}

function initAllNavigationalBox(){
    initAll(".navigational-box", "NavigationalBox");
}

//initAll(".view-more-less", "ViewMoreLess");

function initAll(className, GDKname){
    for(var i=0; i< document.querySelectorAll(className).length;i++) {
        switch (GDKname) {
            case "ViewMoreLess":
                var vml = new GDK.ViewMoreLess({
                    "content": document.querySelectorAll(className)[i]
                });
                break;
            case "Tooltip":
                var tooltip = new GDK.Tooltip({
                    "content": document.querySelectorAll(className)[i]
                });
                break;
            case "TextareaCountdown":
                var tacountdown = new GDK.TextareaCountdown({
                    "content" : document.querySelectorAll(className)[i]
                });
                break;
            case "MoreInfoButton":
                var mib = new GDK.MoreInfoButton({
                    "content" : document.querySelectorAll(className)[i]
                });
                break;
            case "Tabs":
                var tab = new GDK.Tabs({
                    "content" : document.querySelectorAll(className)[i]
                });
                break;
            case "Alert":
                var alert = new GDK.Alert({
                    "content" : document.querySelectorAll(className)[i]
                });
                break;
            case "SortableTable":
                var sortableTable = new GDK.SortableTable({
                    "content" : document.querySelectorAll(className)[i]
                });
                break;
            case "Accordion":
                var accordion = new GDK.Accordion({
                    "content" : document.querySelectorAll(className)[i]
                });
                break;
            case "NavigationalBox":
                var navigationalBox = new GDK.NavigationalBox({
                    "content" : document.querySelectorAll(className)[i]
                });
        }
    }
}

export {initAllTextareaCountdown, initAllToolTips, initAllVML, initAllMoreInfoBtn, initAllTabs, initAllAlert, initAllSortableTable, initAllAccordions, initAllNavigationalBox};
